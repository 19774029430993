import { toast } from "react-hot-toast";
import { setLoading, setSignupData, setToken } from "../../slices/authSlice";
import {
  setUser,
  setDoctorDetails,
  setCompany,
  setLogo,
  setOrganizationDoctor,
  setUserType,
  setAccessRoles,
} from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector";
import { authEndpoints } from "../apis";

const { LOGIN_API, PARTNER_LOGIN_API, CHANGE_PASSWORD_API, FORGOT_PASSWORD_API, VERIFY_FORGOT_PASSWORD_OTP_API, RESET_PASSWORD_API } = authEndpoints;

// LOGIN
export function login(Identifier, Password, LoginType, RememberMe, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      let requestBody;
      if (LoginType === "0") {
        requestBody = {
          Email: Identifier,
          Password,
          LoginType,
        };
      } else {
        requestBody = {
          Phone: Identifier,
          Password,
          LoginType,
        };
      }

      const response = await apiConnector("POST", LOGIN_API, requestBody);
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      if (RememberMe) {
        if (LoginType === "0") {
          localStorage.setItem(
            "savedInfo",
            JSON.stringify({ Email: Identifier, Password, RememberMe })
          );
        } else {
          localStorage.setItem(
            "savedInfo",
            JSON.stringify({ Phone: Identifier, Password, RememberMe })
          );
        }
      } else {
        localStorage.removeItem("savedInfo");
      }

      if (response?.data?.user?.IsPasswordChangeNeeded) {
        toast.success("Password Change Needed");
        dispatch(setSignupData(response?.data?.user));
        navigate("/reset-password");
      } else {
        toast.success("Login Successfull");
        dispatch(setToken(response?.data?.AccessToken));
        const userImage = response?.data?.user?.image
          ? response?.data?.user?.image
          : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.Name}`;
        dispatch(
          setUser({
            ...response?.data?.user,
            image: userImage,
          })
        );
        dispatch(setDoctorDetails(response?.data?.DoctorDetails));
        dispatch(setAccessRoles(response?.data?.user?.AccessRoles));
        navigate("/clinicianDashboard");
      }
    } catch (error) {
      console.log("LOGIN API ERROR :", error);
      toast.error(error?.response?.data?.message || "Login Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// PARTNER LOGIN
export function partnerLogin(
  Identifier,
  Password,
  LoginType,
  RememberMe,
  navigate
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      let requestBody;
      if (LoginType === "0") {
        requestBody = {
          Email: Identifier,
          Password,
          LoginType,
        };
      } else {
        requestBody = {
          Phone: Identifier,
          Password,
          LoginType,
        };
      }

      const response = await apiConnector(
        "POST",
        PARTNER_LOGIN_API,
        requestBody
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      if (RememberMe) {
        if (LoginType === "0") {
          localStorage.setItem(
            "savedInfo",
            JSON.stringify({ Email: Identifier, Password, RememberMe })
          );
        } else {
          localStorage.setItem(
            "savedInfo",
            JSON.stringify({ Phone: Identifier, Password, RememberMe })
          );
        }
      } else {
        localStorage.removeItem("savedInfo");
      }

      if (response?.data?.user?.IsPasswordChangeNeeded) {
        toast.success("Password Change Needed");
        dispatch(setSignupData(response?.data?.user));
        navigate("/reset-password");
      } else {
        toast.success("Login Successfull");
        dispatch(setToken(response?.data?.AccessToken));
        const userImage = response?.data?.user?.image
          ? response?.data?.user?.image
          : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.Name}`;
        dispatch(
          setUser({
            ...response?.data?.user,
            image: userImage,
          })
        );
        dispatch(setDoctorDetails(response?.data?.DoctorDetails));
        dispatch(setAccessRoles(response?.data?.user?.AccessRoles));
        navigate("/labPartnerDashboard");
      }
    } catch (error) {
      console.log("LOGIN API ERROR :", error);
      toast.error(error?.response?.data?.message || "Login Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//FORGOT PASSWORD
export function sendForgotPasswordOtp(Identifier, LoginType, navigate, type) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      let requestBody;
      if (LoginType === "0") {
        requestBody = {
          "Email": Identifier,
          // LoginType,
        };
      } else {
        requestBody = {
          "Phone": Identifier,
          // LoginType,
        };
      }

      const response = await apiConnector(
        "POST",
        FORGOT_PASSWORD_API,
        requestBody
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const localStorageData = {
        ...requestBody,
        LoginType: LoginType,
      };

      toast.success("OTP Sent Successfully");
      localStorage.setItem("tempData", JSON.stringify(localStorageData));
      if (type === "clinic")
        navigate("/verify-forgot-password-otp");
      else navigate("/partner-verify-forgot-password-otp")
    } catch (error) {
      console.log("SEND FORGOT PASSWORD OTP API", error);
      toast.error(
        error?.response?.data?.message ||
        "Failed to send otp for password reset"
      );
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//VERIFY FORGOT PASSOWRD OTP
export function verifyForgotPasswordOTP(Identifier, Otp, LoginType, navigate, type) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      let requestBody;
      if (LoginType === "0") {
        requestBody = {
          "Email": Identifier,
          "Otp": Otp,
        };
      } else {
        requestBody = {
          "Phone": Identifier,
          "Otp": Otp,
        };
      }
      const response = await apiConnector(
        "POST",
        VERIFY_FORGOT_PASSWORD_OTP_API,
        requestBody
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("OTP Verified Successfully");
      localStorage.setItem("resetToken", response?.data?.AccessToken);
      localStorage.setItem("resetOtp", Otp);
      // localStorage.removeItem("tempData");
      if (type === "clinic")
        navigate("/reset-password");
      else navigate("/partner-reset-password")
    } catch (error) {
      console.log("OTP VERFICATION API ERROR", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
      // navigate("/forgot-password");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//RESET PASSWORD
export function resetPassword(data, navigate, type) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", RESET_PASSWORD_API, data);
      // const response = await apiConnector("POST", RESET_PASSWORD_API, data, {
      //   Authorization: `Bearer ${token}`,
      // });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Password Reset Successfully");
      if (type === "clinic") {
        navigate("/reset-password-success");
      }
      else { navigate("/partner-reset-password-success") }
      localStorage.removeItem("tempData");
      localStorage.removeItem("resetToken");
      localStorage.removeItem("resetOtp");
    } catch (error) {
      console.log("RESET PASSWORD API ERROR", error);
      toast.error(error?.response?.data?.message || "Failed to reset password");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//CHANGE PASSWORD
export function changePassword(data, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("PUT", CHANGE_PASSWORD_API, data, {
        // Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Password Changed Successfully");
      navigate("/reset-password-success");
    } catch (error) {
      console.log("CHANGE PASSWORD API ERROR :", error);
      toast.error(error?.response?.data?.message || "Password Change Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//LOGOUT
export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    toast.success("Logged Out");
    navigate("/");
  };
}