import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import Breadcrumb from "../components/Breadcrumb";
import Pagination from "../components/Pagination";
import { deleteLabPartners } from "../services/operations/masterDataAPI";
import ModalComponent from "../components/Modal";
import Tooltip from "../components/Tooltip";
import deleteIcon from "../assets/Images/MedicalConcierge/deleteRedIcon.svg";

function LabPartners() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { allLabPartners, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLabPartner, setSelectedLabPartner] = useState(null);
  const filteredLabPartners = allLabPartners.filter((p) => {
    return p?.Name?.toLowerCase().includes(searchInput?.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLabPartners = filteredLabPartners.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteService = (id) => {
    dispatch(deleteLabPartners(token, id));
    setShowDeleteModal(false);
    setSelectedLabPartner(null);
  };

  if (masterDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <ModalComponent
        show={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
          setSelectedLabPartner(null);
        }}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteIcon} alt="" />
          </div>
          <div className="text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Remove '{selectedLabPartner?.Name}'?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Once Removed, they will be not access to their dashboard.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={() => {
                setShowDeleteModal(false);
                setSelectedLabPartner(null);
              }}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={() => handleDeleteService(selectedLabPartner?.LPID)}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Remove
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>

      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Lab Partners
        </div>
        <button
          onClick={() => {
            navigate("addLabPartner");
          }}
          className="px-3 py-2 bg-sky-400 rounded shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
        >
          <FaPlus className="text-white text-sm" />
          <div className="text-white text-sm font-normal font-poppins">
            Add New Lab Partner
          </div>
        </button>
      </div>
      <Breadcrumb />

      {/* Search Bar */}
      <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2 mt-6">
        <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
        <input
          type="text"
          placeholder="Search for Lab Partners"
          value={searchInput}
          onChange={handleSearchChange}
          className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
        />
      </label>

      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-gray-50 border-b border-gray-200">
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Lab Name
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Address
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Phone Number
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Type of Lab
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Status
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLabPartners?.map((lab) => (
              <tr
                key={lab?.LPID}
                className="border-b border-gray-200 hover:bg-sky-50"
              >
                <td className="p-4">
                  <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                    {lab?.Name}
                  </p>
                  <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                    ID: {lab?.LPID}
                  </p>
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {lab?.Street +
                    ", " +
                    lab?.City +
                    ", " +
                    lab?.State +
                    ", " +
                    lab?.Country}
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {lab?.Phone}
                </td>
                <td className="p-4">
                  <div className="flex flex-row flex-wrap gap-2">
                    {lab?.LabTypes?.map((item) => (
                      <div
                        key={item?.LTID}
                        className="px-2.5 py-1 bg-sky-100 rounded-xl"
                      >
                        <div className="text-sky-400 text-sm font-semibold font-poppins">
                          {item?.Name}
                        </div>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="p-4">
                  <div
                    className={`w-32 p-2 ${
                      lab?.Status === "Active"
                        ? "bg-lime-100 text-lime-500"
                        : lab?.Status === "Pending"
                        ? "bg-neutral-300 text-zinc-500"
                        : "bg-red-200 text-rose-500"
                    } rounded-lg`}
                  >
                    <div className="text-center  text-xs font-semibold font-poppins">
                      {lab?.Status}
                    </div>
                  </div>
                  {/* <button
                    className="text-sky-400 text-xs font-semibold font-poppins underline w-32"
                    onClick={() => {
                      navigate("addLabPartner", {
                        state: { ...item, isViewing: true },
                      });
                    }}
                  >
                    See Details
                  </button> */}
                </td>
                <td className="p-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Tooltip message="View">
                      <button
                        onClick={() => {
                          navigate("addLabPartner", {
                            state: { ...lab, isViewing: true },
                          });
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    <Tooltip message="Edit">
                      <button
                        onClick={() => {
                          navigate("addLabPartner", {
                            state: { ...lab },
                          });
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    <Tooltip message="Delete">
                      <button
                        onClick={() => {
                          setSelectedLabPartner(lab);
                          setShowDeleteModal(true);
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M9.30078 16.5V10.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M14.3008 16.5V10.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalItems={filteredLabPartners.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default LabPartners;
