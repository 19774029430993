import React, { useEffect, useState } from "react";
import TitleWithAddButton from "./TitleWithAddButton";
import uploadDocument from "../assets/Images/PatientProfile/uploadDocument.png";
import seeDocument from "../assets/Images/PatientProfile/seeDocument.png";
import uploadFile from "../assets/Images/PatientProfile/uploadFile.png";
import PdfComp from "../components/PdfComp";
import { pdfjs } from "react-pdf";
import claim_report from "../assets/Images/Report/claim_report.png";
import doctor_prescription from "../assets/Images/Report/doctor_prescription.png";
import imaging from "../assets/Images/Report/imaging.png";
import lab_reports from "../assets/Images/Report/lab_reports.png";
import other_reports from "../assets/Images/Report/other_reports2.png";
import claim_report2 from "../assets/Images/Report/claim_report2.png";
import doctor_prescription2 from "../assets/Images/Report/doctor_prescription2.png";
import imaging2 from "../assets/Images/Report/imaging2.png";
import lab_reports2 from "../assets/Images/Report/lab_reports2.png";
import other_reports2 from "../assets/Images/Report/other_reports.png";
import ModalComponent from "../components/Modal";
import toast from "react-hot-toast";
import { IoIosAdd } from "react-icons/io";
import { RxEnterFullScreen } from "react-icons/rx";
import { LuDownload } from "react-icons/lu";
import { FiPrinter } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDecryptedReport,
  getHealthReports,
  saveKeyBioMarkers,
  uploadHealthReport,
} from "../services/operations/patientManagementAPI";
import moment from "moment";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function UploadedReports({
  hasEditAccess,
  consultationHistory,
  patient,
  allMedicines,
  diagnosisType,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { healthReports } = useSelector((state) => state.patient);
  const [formData, setFormData] = useState({
    DiseaseType: "",
    Document: "",
  });
  const [errors, setErrors] = useState({
    DiseaseType: "",
    Document: "",
  });

  // ***************************************************************
  const [doctorsPrescription, setDoctorsPrescription] = useState([]);
  const [labReports, setLabReports] = useState([]);
  const [imagingReports, setImagingReports] = useState([]);
  const [claimReports, setClaimReports] = useState([]);
  const [otherReports, setOtherReports] = useState([]);
  const [uploadedReport, setUploadedReport] = useState([]);
  // ***************************************************************

  const [isReportTypeModalOpen, setReportTypeModalOpen] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [isUploadDocumentModalOpen, setUploadDocumentModalOpen] =
    useState(false);
  const [viewUploadedDocument, setViewUploadedDocument] = useState(false);
  const [viewUploadedDocumentType, setViewUploadedDocumentType] = useState("");
  const [openDocument, setOpenDocument] = useState(false);

  // ***************************************************************

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const documents = [
    {
      id: 1,
      title: "Doctor's Prescription",
      label: "Doctors Prescription",
      image: doctor_prescription,
      background: "bg-sky-400",
      files: doctorsPrescription.length,
      lastUpload:
        doctorsPrescription.length > 0
          ? moment(doctorsPrescription[0]?.UploadDate).format("DD/MM/YYYY")
          : "N/A",
    },
    {
      id: 2,
      title: "Lab Reports",
      label: "Lab Reports",
      image: lab_reports,
      background: "bg-amber-500",
      files: labReports.length,
      lastUpload:
        labReports.length > 0
          ? moment(labReports[0]?.UploadDate).format("DD/MM/YYYY")
          : "N/A",
    },
    {
      id: 3,
      title: "Imaging (X-Ray, MRI)",
      lable: "Imaging",
      image: imaging,
      background: "bg-amber-500",
      files: imagingReports.length,
      lastUpload:
        imagingReports.length > 0
          ? moment(imagingReports[0]?.UploadDate).format("DD/MM/YYYY")
          : "N/A",
    },
    {
      id: 4,
      title: "Other Reports",
      label: "Others Reports",
      image: other_reports2,
      background: "bg-sky-400",
      files: otherReports.length,
      lastUpload:
        otherReports.length > 0
          ? moment(otherReports[0]?.UploadDate).format("DD/MM/YYYY")
          : "N/A",
    },
    {
      id: 5,
      title: "Claims Reports",
      label: "Claims Document",
      image: claim_report,
      background: "bg-amber-500",
      files: claimReports.length,
      lastUpload:
        claimReports.length > 0
          ? moment(claimReports[0]?.UploadDate).format("DD/MM/YYYY")
          : "N/A",
    },
  ];

  const handleReportTypeSelect = (item) => {
    setSelectedReportType(item);
    setReportTypeModalOpen(false);
    setUploadDocumentModalOpen(true);
  };

  const handleViewUploadedDocument = (item) => {
    setUploadedReport(
      healthReports.filter((report) => report.PathReportType === item.label)
    );
    setViewUploadedDocumentType(item);
    setViewUploadedDocument(true);
  };

  const handleOpenDocument = (item) => {
    fetchDecryptedReport(item);
    setSelectedDocument(item);
    setViewUploadedDocument(false);
    setOpenDocument(true);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return; // Check if a file is selected

    // Check if the file size is more than 10 MB
    if (file.size > 10 * 1024 * 1024) {
      toast.error("File exceeds 10MB limit.");
      e.target.value = null;
      return;
    }
    setSelectedFile(file);
    // uploadReport(file);
    e.target.value = null;
    setFormData((prev) => ({
      ...prev,
      Document: file,
    }));
    setErrors((prev) => ({
      ...prev,
      Document: "",
    }));
  };

  const uploadReport = async (file) => {
    const mandatoryFields = ["DiseaseType", "Document"];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    setIsUploading(true);
    const fd = new FormData();
    fd.append("documents", file);
    fd.append("AHUID", patient.AHUID);
    fd.append("DiagnosisID", formData.DiseaseType);
    fd.append("ReportType", selectedReportType.label);
    fd.append("DocumentType", "1");
    fd.append("Notes", selectedReportType.title);
    fd.append("DateOfOccurrence", moment().format("YYYY-MM-DD"));
    try {
      const res = await uploadHealthReport(token, fd);
      if (res) {
        addBioMarker(res);
      }
    } catch (error) {
      console.log(error);
      setIsUploading(false);
    }
  };

  const addBioMarker = async (body) => {
    try {
      const data = {
        AHUID: patient.AHUID,
        diagnosisReportsID: [body?.diagnosisReports[0]?.ID],
        healthEpisodeID: body?.healthEpisode?.ID,
        ConsentGiven: true,
        PathReportType: selectedReportType.label,
      };

      const res = await saveKeyBioMarkers(token, data);
      if (res.BioMarker[0].length > 1) {
        toast.success("Biomarkers extracted successfully");
        const data = res.BioMarker;
        setUploadDocumentModalOpen(false);
        setSelectedFile(null);
        setUploadedReport([]);
        navigate("viewBiomarker", { state: { data, patient } });
      } else {
        toast.success("Report uploaded successfully");
        setUploadDocumentModalOpen(false);
        setSelectedFile(null);
        setUploadedReport([]);
      }
      dispatch(getHealthReports(token, patient.AHUID));
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (healthReports) {
      const doctorsPrescription = healthReports.filter(
        (report) => report.PathReportType === "Doctors Prescription"
      );
      setDoctorsPrescription(doctorsPrescription);

      const labReports = healthReports.filter(
        (report) => report.PathReportType === "Lab Reports"
      );
      setLabReports(labReports);

      const imaging = healthReports.filter(
        (report) => report.PathReportType === "Imaging"
      );
      setImagingReports(imaging);

      const claimReports = healthReports.filter(
        (report) => report.PathReportType === "Claims Document"
      );
      setClaimReports(claimReports);

      const otherReports = healthReports.filter(
        (report) => report.PathReportType === "Others Reports"
      );
      setOtherReports(otherReports);
    }
  }, [healthReports]);

  const fetchDecryptedReport = async (item) => {
    try {
      const data = {
        PhysicalReport: item?.PhysicalReport,
        ReportID: item?.ReportID,
        AHUID: patient?.AHUID,
      };
      const res = await getDecryptedReport(token, data);
      setPdfUrl(res?.filePath);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFullScreen = () => {
    window.open(pdfUrl, "_blank").focus();
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(pdfUrl);
      if (!response.ok) throw new Error("Network response was not ok.");
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = selectedDocument?.FileName || "Report";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const handlePrint = () => {
    const printWindow = window.open(pdfUrl);
    printWindow.addEventListener(
      "load",
      () => {
        printWindow.print();
      },
      false
    );
  };

  function handleOnChange(event) {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const filteredReportType = hasEditAccess
    ? REPORTTYPE
    : REPORTTYPE.filter((report) => !report.isUploadButton);

  return (
    <div className="">
      <TitleWithAddButton
        title="View & Upload"
        showViewButton={true}
        onPress={() => navigate("healthJourney", { state: patient })}
      />

      {/* Upload Document (Report Type) */}
      <ModalComponent
        show={isReportTypeModalOpen}
        handleClose={() => setReportTypeModalOpen(false)}
        outSideModalPress={false}
      >
        <div className="text-black text-base font-bold font-poppins mb-4">
          Upload Document
        </div>
        <div className="border border-b-1 border-gray-200 mb-4"></div>
        <div className="grid grid-cols-2 gap-4">
          {documents.map((document) => (
            <div
              key={document.id}
              onClick={() => handleReportTypeSelect(document)}
              className="bg-white p-4 rounded-lg cursor-pointer flex flex-col items-center justify-center space-y-1 shadow-xl hover:scale-105 transition-all duration-300"
            >
              <div
                className={`${document?.background} w-12 h-12 rounded-full justify-center items-center inline-flex`}
              >
                <img
                  src={document.image}
                  className="w-9 h-9 object-contain"
                  alt={document.title}
                />
              </div>
              <div className="text-black text-sm font-semibold font-poppins">
                {document.title}
              </div>
              <div className="flex text-neutral-400 text-xs font-normal font-poppins items-center justify-center">
                {document.files} Files
                <div className="w-1.5 h-1.5 bg-zinc-300 rounded-full mx-2" />
                Last Upload : {document.lastUpload}
              </div>
            </div>
          ))}
        </div>
      </ModalComponent>

      {/* Upload Document (Upload File) */}
      <ModalComponent
        show={isUploadDocumentModalOpen}
        handleClose={() => {
          setUploadDocumentModalOpen(false);
          setSelectedFile(null);
          setUploadedReport([]);
          setFormData({
            DiseaseType: "",
          });
          setErrors({
            DiseaseType: "",
          });
        }}
        outSideModalPress={false}
      >
        <div className="flex flex-row items-center justify-between mb-4">
          <div className="flex flex-row items-center space-x-4">
            <div
              className={`${selectedReportType?.background} w-12 h-12 rounded-full justify-center items-center inline-flex`}
            >
              <img
                src={selectedReportType.image2 || selectedReportType.image}
                className="w-9 h-9 object-contain"
                alt={selectedReportType.title}
              />
            </div>
            <div>
              <div className="text-black text-sm font-semibold font-poppins">
                {selectedReportType.title}
              </div>
              <div className="text-zinc-500 text-xs font-normal font-poppins">
                {selectedReportType.files || uploadedReport.length} Files
                Uploaded
              </div>
            </div>
          </div>
          <div>
            <div className="text-black text-xs font-normal font-poppins leading-none">
              Latest Upload :
            </div>
            <div className="text-right text-black text-xs font-semibold font-poppins leading-none mt-1">
              {selectedReportType.lastUpload
                ? selectedReportType.lastUpload
                : uploadedReport.length > 0
                ? moment(uploadedReport[0]?.UploadDate).format("DD/MM/YYYY")
                : "N/A"}
            </div>
          </div>
        </div>
        <div className="border border-b-1 border-gray-200 mb-4"></div>
        <div className="w-1/2 mb-4">
          <label>
            <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
              Disease Type
            </p>
            <select
              id="DiseaseType"
              name="DiseaseType"
              value={formData.DiseaseType}
              className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                errors.DiseaseType ? "border-red-500" : ""
              } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              onChange={handleOnChange}
            >
              <option value="">Select Disease Type</option>
              {diagnosisType.map((diagnosis) => (
                <option key={diagnosis.ID} value={diagnosis.ID}>
                  {diagnosis.DiagnosisName}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="mb-4">
          <label
            className={`w-[638px] h-72 border-dashed ${
              errors.Document ? "border-red-500" : "border-sky-400"
            } border-2 rounded-lg flex flex-col items-center justify-center cursor-pointer`}
          >
            {selectedFile ? (
              <>
                <img
                  src="https://static.vecteezy.com/system/resources/previews/023/234/824/original/pdf-icon-red-and-white-color-for-free-png.png"
                  alt="upload icon"
                  className="w-20 h-20 object-contain"
                />
                {/* show file name over here  */}
                <div className="text-center">{selectedFile?.name}</div>

                <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center inline-flex mt-3">
                  <p className="text-sky-400 text-xs font-semibold font-poppins">
                    Change Document
                  </p>
                </div>
              </>
            ) : (
              <>
                <img
                  src={uploadDocument}
                  alt="upload icon"
                  className="w-10 h-10 object-contain"
                />
                <span className="text-sky-400 text-base font-normal font-poppins mt-2">
                  Upload Document Here
                </span>
                <span className="text-neutral-400 text-sm font-normal font-poppins mt-1">
                  Max file size: 10MB
                </span>
              </>
            )}
            <input
              type="file"
              accept="application/pdf"
              name="pdfLocation"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </label>
        </div>
        <div className="flex justify-center items-center">
          <button
            disabled={isUploading}
            onClick={() => {
              uploadReport(selectedFile);
            }}
            className={`px-11 py-3 text-base font-semibold font-poppins rounded-lg shadow bg-sky-400 text-center text-white hover:shadow-lg hover:bg-sky-600`}
          >
            Upload File
          </button>
        </div>
      </ModalComponent>

      {/* List of Uploaded Documents */}
      <ModalComponent
        show={viewUploadedDocument}
        handleClose={() => {
          setViewUploadedDocument(false);
          setUploadedReport([]);
        }}
        outSideModalPress={false}
      >
        <div className="w-[40vw]">
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex flex-row items-center space-x-4">
              <div
                className={`${viewUploadedDocumentType?.background} w-12 h-12 rounded-full justify-center items-center inline-flex`}
              >
                <img
                  src={viewUploadedDocumentType.image2}
                  className="w-9 h-9 object-contain"
                  alt={viewUploadedDocumentType.title}
                />
              </div>
              <div>
                <div className="text-black text-sm font-semibold font-poppins">
                  {viewUploadedDocumentType.title}
                </div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  {uploadedReport?.length} Files Uploaded
                </div>
              </div>
            </div>
            <div>
              <div className="text-black text-xs font-normal font-poppins leading-none">
                Latest Upload :
              </div>
              <div className="text-right text-black text-xs font-semibold font-poppins leading-none mt-1">
                {uploadedReport.length > 0
                  ? moment(uploadedReport[0]?.UploadDate).format("DD/MM/YYYY")
                  : "N/A"}
              </div>
            </div>
          </div>
          <div className="border border-b-1 border-gray-200 mb-4"></div>

          {hasEditAccess && (
            <div className="flex items-center justify-center mb-4">
              <button
                onClick={() => {
                  setViewUploadedDocument(false);
                  handleReportTypeSelect(viewUploadedDocumentType);
                }}
                className=" w-1/2 active:scale-95 shadow-lg bg-sky-100 rounded-lg flex flex-row items-center justify-center space-x-2 py-4"
              >
                <img
                  src={uploadFile}
                  alt=""
                  className="w-6 h-6 object-contain"
                />
                <div className="text-sky-400 text-sm font-semibold font-poppins">
                  Upload {viewUploadedDocumentType.title}
                </div>
              </button>
            </div>
          )}

          {uploadedReport.length === 0 && (
            <div className="w-full h-48 bg-stone-50 rounded-xl border border-dashed border-neutral-400 flex items-center justify-center">
              <div className="text-zinc-500 text-sm font-semibold font-open">
                No Data Added yet....
              </div>
            </div>
          )}

          <div className="px-4 max-h-[calc(100vh-400px)] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            <div className="flex flex-col items-center justify-center space-y-4 ">
              {uploadedReport?.map((report) => (
                <div
                  key={report.ID}
                  className="w-full h-20 bg-zinc-100 rounded-lg flex flex-row justify-between items-center p-4"
                >
                  <div>
                    <div className="text-black text-xs font-semibold font-poppins">
                      {report?.DiagnosisName}
                    </div>
                    <div className="text-zinc-500 text-xs font-normal font-poppins mt-1">
                      {moment(report?.UploadDate).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <div
                    className="flex flex-row items-center space-x-2 cursor-pointer"
                    onClick={() => {
                      handleOpenDocument(report);
                    }}
                  >
                    <img
                      src={seeDocument}
                      alt={report.title}
                      className="w-6 h-6 object-contain"
                    />
                    <div className="text-sky-400 text-xs font-normal font-poppins">
                      See Document
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Open Document */}
      <ModalComponent
        show={openDocument}
        handleClose={() => {
          setOpenDocument(false);
          setViewUploadedDocument(true);
          setPdfUrl(null);
        }}
        outSideModalPress={false}
      >
        <div className="flex flex-row items-center justify-between mb-4">
          <div className="flex flex-row items-center space-x-4">
            <div
              className={`${viewUploadedDocumentType?.background} w-12 h-12 rounded-full justify-center items-center inline-flex`}
            >
              <img
                src={viewUploadedDocumentType.image2}
                className="w-9 h-9 object-contain"
                alt={viewUploadedDocumentType.title}
              />
            </div>
            <div>
              <div className="text-black text-sm font-semibold font-poppins">
                {viewUploadedDocumentType.title}
              </div>
              <div className="text-zinc-500 text-xs font-normal font-poppins">
                {selectedDocument.title}
              </div>
            </div>
          </div>
          <div>
            <div className="text-black text-xs font-normal font-poppins leading-none">
              Date Upload :
            </div>
            <div className="text-right text-black text-xs font-semibold font-poppins leading-none mt-1">
              {moment(selectedDocument.UploadDate).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
        <div className="border border-b-1 border-gray-200 mb-4"></div>
        <div className="m-5">
          <div
            className={`flex flex-col items-center justify-center overflow-y-auto`}
          >
            {/* {pdfUrl && <PdfComp pdf={pdfUrl} />} */}
            <PdfComp pdf={pdfUrl} />
          </div>
        </div>
        <div className="flex justify-end space-x-2 p-2">
          <button
            className="w-32 h-11 py-3 bg-sky-400 rounded-lg flex items-center justify-center gap-2 shadow-md active:scale-95 hover:scale-105 transform transition-all duration-200"
            onClick={handleFullScreen}
          >
            <RxEnterFullScreen className="text-white text-md" />
            <div className="text-white text-md font-normal font-poppins">
              Full Screen
            </div>
          </button>
          <button
            className="w-32 h-11 py-3 bg-sky-400 rounded-lg flex items-center justify-center gap-2 shadow-md active:scale-95 hover:scale-105 transform transition-all duration-200"
            onClick={handleDownload}
          >
            <LuDownload className="text-white text-md" />
            <div className="text-white text-md font-normal font-poppins">
              Download
            </div>
          </button>
          <button
            className="w-32 h-11 py-3 bg-sky-400 rounded-lg flex items-center justify-center gap-2 shadow-md active:scale-95 hover:scale-105 transform transition-all duration-200"
            onClick={handlePrint}
          >
            <FiPrinter className="text-white text-md" />
            <div className="text-white text-md font-normal font-poppins">
              Print
            </div>
          </button>
        </div>
      </ModalComponent>

      <button onClick={() => navigate("viewBiomarker", { state: patient })}>
        Hello{" "}
      </button>

      <div className="grid grid-cols-3 gap-6 mt-10">
        {filteredReportType.map((document) => (
          <button
            key={document.id}
            onClick={() =>
              document.isUploadButton
                ? setReportTypeModalOpen(true)
                : handleViewUploadedDocument(document)
            }
            className="flex flex-col items-center group"
          >
            {document.isUploadButton ? (
              <>
                <div className="w-20 h-20 flex items-center justify-center active:scale-95">
                  <div className="w-12 h-12 bg-sky-100 rounded-full border border-dashed border-sky-400 flex items-center justify-center">
                    <IoIosAdd className="w-9 h-9 text-sky-400" />
                  </div>
                </div>
                <div className="w-16 text-center text-black text-xs font-normal font-poppins mt-2">
                  {document.title}
                </div>
              </>
            ) : (
              <>
                <div
                  className={`w-20 h-20 rounded-xl border border-stone-300 flex items-center justify-center group-hover:shadow-lg group-hover:scale-105 transition-all duration-300 ${
                    document.background === "bg-amber-500"
                      ? "group-hover:bg-amber-500"
                      : "group-hover:bg-sky-400"
                  }`}
                >
                  <img
                    src={document.image}
                    className="w-9 h-9 object-contain group-hover:hidden transition-all duration-300"
                    alt={document.title}
                  />
                  <img
                    src={document.image2}
                    className="w-9 h-9 object-contain hidden group-hover:block transition-all duration-300"
                    alt={document.title}
                  />
                </div>
                <div className="w-16 text-center text-black text-xs font-normal font-poppins mt-2">
                  {document.title}
                </div>
              </>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}

export default UploadedReports;

const REPORTTYPE = [
  {
    id: 1,
    title: "Lab Reports",
    label: "Lab Reports",
    image: lab_reports2,
    image2: lab_reports,
    background: "bg-amber-500",
  },
  {
    id: 2,
    title: "Imaging (X-Ray, MRI)",
    label: "Imaging",
    image: imaging2,
    image2: imaging,
    background: "bg-amber-500",
  },
  {
    id: 3,
    title: "Claims Document",
    label: "Claims Document",
    image: claim_report2,
    image2: claim_report,
    background: "bg-amber-500",
  },
  {
    id: 4,
    title: "Doctor's Prescription",
    label: "Doctors Prescription",
    image: doctor_prescription2,
    image2: doctor_prescription,
    background: "bg-sky-400",
  },
  {
    id: 5,
    title: "Other Reports",
    label: "Others Reports",
    image: other_reports,
    image2: other_reports2,
    background: "bg-sky-400",
  },
  {
    id: 6,
    title: "Upload Here",
    isUploadButton: true,
  },
];
