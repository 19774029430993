import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import appointment from "../assets/Images/MedicalConcierge/appointment.svg";
import call from "../assets/Images/MedicalConcierge/call.svg";
import Tooltip from "./Tooltip";
import ModalComponent from "./Modal";
import addHealthManagerIcon from "../assets/Images/MedicalConcierge/addHealthManagerIcon.svg";
import notesIcon from "../assets/Images/MedicalConcierge/notesIcon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotes,
  assignHealthManager,
} from "../services/operations/medicalConciergeAPI";
import Pagination from "./Pagination";
import EditUserDetailsModal from "./EditUserDetailsModal";
import { getPatientProfile } from "../services/operations/patientManagementAPI";
import { set } from "date-fns";

function ConciergeMembers({ users, managers, loading }) {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading: patientLoading, patientProfile } = useSelector(
    (state) => state.patient
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [showAssignManagerModal, setShowAssignManagerModal] = useState(false);
  const [showAddNotesModal, setShowAddNotesModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showEditComp, setShowEditComp] = useState(false);
  const [showHealthManagerList, setShowHealthManagerList] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedHealthManager, setSelectedHealthManager] = useState(null);
  const [notes, setNotes] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) => {
    return user?.FirstName?.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const closeAssignManagersModal = () => {
    setShowAssignManagerModal(false);
    setShowHealthManagerList(false);
  };

  const closeAddNotesModal = () => {
    setShowAddNotesModal(false);
    setNotes("");
  };

  // Function to handle search input change for each user
  const handleSearchInputChange = (event) => {
    if (selectedHealthManager !== null) {
      setSelectedHealthManager(null);
    }
    setSearchInput(event.target.value);
  };

  // Function to handle health manager selection
  const handleHealthManagerSelect = (manager) => {
    setSelectedHealthManager(manager);
    setShowHealthManagerList(false);
    setSearchInput("");
  };

  // Function to filter health managers based on search input for each user
  const filteredHealthManagers = managers.filter((manager) =>
    manager?.Name?.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleAddNotes = () => {
    const data = {
      AHUID: selectedPatient?.AHUID,
      Notes: notes,
    };
    dispatch(addNotes(token, data));
    setShowAddNotesModal(false);
    setNotes("");
  };

  const handleAssignHealthManager = () => {
    const data = {
      AHUID: selectedPatient?.AHUID,
      DID: selectedHealthManager?.DID,
    };
    dispatch(assignHealthManager(token, data));
    setShowAssignManagerModal(false);
    setSelectedHealthManager(null);
  };

  const fetchPatientProfile = (user) => {
    let body = {
      AHUID: user?.AHUID,
    };
    dispatch(getPatientProfile(token, body));
    setShowEditComp(true);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  if (users?.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6 m-6">
        <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
          No Data
        </div>
      </div>
    );
  }

  return (
    <div>
      <ModalComponent
        show={showAssignManagerModal}
        handleClose={closeAssignManagersModal}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-row items-center justify-start space-x-4">
          <div className="w-10 h-10 bg-sky-100 rounded-3xl flex justify-center items-center">
            <img src={addHealthManagerIcon} alt="" />
          </div>
          <div className="text-slate-800 text-xl font-medium font-poppins leading-normal">
            Assign personal health manager for:
          </div>
        </div>
        <div className="border border-b-1 border-gray-100 my-3" />
        <div className="flex items-center">
          <div
            className={`${
              selectedPatient?.IsMedicalConciergeMember &&
              " bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5"
            }`}
          >
            <img
              src={selectedPatient?.Image}
              alt="PatientPic"
              className="w-10 h-10 rounded-full "
            />
          </div>
          <div
            onClick={() => {
              navigate("patientProfile", { state: selectedPatient });
            }}
            className="text-sky-400 text-sm font-semibold font-poppins underline leading-tight cursor-pointer ml-3"
          >
            {selectedPatient?.FirstName + " " + selectedPatient?.LastName}
          </div>
        </div>
        <div className="container w-[35vw] mx-auto mt-6 py-4 px-6 shadow rounded-md">
          <div className="flex flex-row items-center">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Gender & Age
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins  leading-tight">
              {selectedPatient?.Gender},{" "}
              {new Date().getFullYear() -
                new Date(selectedPatient?.DOB).getFullYear()}
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <div className="flex flex-row items-center border-b-1 border-gray-100">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Contact
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins  leading-tight">
              {selectedPatient?.Phone} ; {selectedPatient?.Email}
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <div className="flex flex-row items-center border-b-1 border-gray-100">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Member since
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins  leading-tight">
              {moment(selectedPatient?.MedicalConciergeJoiningDate).format(
                "DD.MM.YY"
              )}
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <div className="flex flex-row items-center border-b-1 border-gray-100">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Assigned health manager
            </div>
            <div className="flex-1 relative">
              <label>
                <input
                  type="text"
                  value={selectedHealthManager?.Name}
                  onChange={handleSearchInputChange}
                  onFocus={() => {
                    setShowHealthManagerList(true);
                  }}
                  placeholder="Select"
                  className="w-full h-10 p-4 bg-gray-100 rounded-lg border flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-sky-400"
                  autoComplete="off"
                />
                <div className="absolute inset-y-0 right-3 pr-4 flex items-center text-gray-400">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </label>
              {showHealthManagerList && (
                <div className="absolute left-0 mt-1 w-full bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10">
                  {filteredHealthManagers?.length > 0 ? (
                    filteredHealthManagers.slice(0, 4).map((manager) => (
                      <button
                        key={manager.DID}
                        onClick={() => handleHealthManagerSelect(manager)}
                        className={`flex items-center justify-between w-full text-left px-4 py-2 hover:bg-gray-100 ${
                          selectedHealthManager?.DID === manager.DID
                            ? "text-sky-400"
                            : "text-zinc-900 "
                        } text-sm font-normal font-poppins leading-tight`}
                      >
                        {manager.Name}
                        {selectedHealthManager?.DID === manager.DID && (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 14.5C5 14.5 6.5 14.5 8.5 18C8.5 18 14.0588 8.83333 19 7"
                              stroke="#3CA5DC"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                      </button>
                    ))
                  ) : (
                    <div className="text-center text-zinc-900 text-sm font-normal font-poppins leading-tight py-2">
                      No manager found
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center mt-6 space-x-4">
          <button
            onClick={closeAssignManagersModal}
            className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
          >
            <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
              Cancel
            </div>
          </button>
          <button
            onClick={handleAssignHealthManager}
            className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
          >
            <div className="text-white text-base font-semibold font-poppins leading-normal">
              Confirm
            </div>
          </button>
        </div>
      </ModalComponent>

      {/* Add Notes Modal */}
      <ModalComponent
        show={showAddNotesModal}
        handleClose={closeAddNotesModal}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="w-[23vw]">
          <div className="flex flex-row items-center justify-start space-x-4">
            <div className="w-10 h-10 bg-sky-100 rounded-3xl flex justify-center items-center">
              <img src={notesIcon} alt="" />
            </div>
            <div className="text-slate-800 text-xl font-medium font-poppins leading-normal">
              Add notes for :
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <div className="flex items-center">
            <div
              className={`${
                selectedPatient?.IsMedicalConciergeMember &&
                " bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5"
              }`}
            >
              <img
                src={selectedPatient?.Image}
                alt="PatientPic"
                className="w-10 h-10 rounded-full "
              />
            </div>
            <div
              onClick={() => {
                navigate("/patientDashboard/patientProfile", {
                  state: selectedPatient,
                });
              }}
              className="text-sky-400 text-sm font-semibold font-poppins underline leading-tight cursor-pointer ml-3"
            >
              {selectedPatient?.FirstName + " " + selectedPatient?.LastName}
            </div>
          </div>
          <div className="mt-5">
            <label>
              <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                Notes
              </p>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="w-full h-32 p-4 bg-gray-100 rounded-lg border focus:outline-none focus:ring-1 focus:ring-sky-400"
                placeholder="Add notes"
              />
            </label>
          </div>
          <div className="flex items-center justify-start mt-4 space-x-2">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 12.7148C22 7.19199 17.5228 2.71484 12 2.71484C6.47715 2.71484 2 7.19199 2 12.7148C2 18.2376 6.47715 22.7148 12 22.7148C17.5228 22.7148 22 18.2376 22 12.7148Z"
                stroke="black"
                stroke-width="1.5"
              />
              <path
                d="M12.2422 17.7148V12.7148C12.2422 12.2434 12.2422 12.0077 12.0957 11.8612C11.9493 11.7148 11.7136 11.7148 11.2422 11.7148"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9922 8.71484H12.0012"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div className="text-neutral-600 text-xs font-normal font-poppins">
              These notes are not shared with Patients. Please avoid adding
              clinical information.{" "}
            </div>
          </div>
          <div className="flex flex-row items-center justify-center mt-4 space-x-4">
            <button
              onClick={closeAddNotesModal}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleAddNotes}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Confirm
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>

      {showEditComp && !patientLoading && (
        <EditUserDetailsModal
          patient={selectedPatient}
          patientProfile={patientProfile}
          navigateTo={"/medicalConcierge"}
          setShowEditComp={setShowEditComp}
        />
      )}
      {/* Search Bar */}
      <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2 mt-6">
        <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
        <input
          type="text"
          placeholder="Search for Patients"
          value={searchTerm}
          onChange={handleSearchChange}
          className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
        />
      </label>

      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-gray-50 border-b border-gray-200">
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Name
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Joining Date
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Assigned Health Manager
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Notes
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Actions
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Requests/ Remarks
              </th>
            </tr>
          </thead>
          <tbody>
            {currentUsers?.map((user) => (
              <tr
                key={user.AHUID}
                className="border-b border-gray-200 hover:bg-sky-50"
              >
                <td className="p-4">
                  <div
                    onClick={() => {
                      navigate("/patientDashboard/patientProfile", {
                        state: user,
                      });
                    }}
                    className="flex items-center cursor-pointer"
                  >
                    <div
                      className={`bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5 mr-3 `}
                    >
                      <img
                        src={user?.Image}
                        alt="PatientPic"
                        className="w-10 h-10  rounded-full"
                      />
                    </div>
                    <div>
                      <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                        {user?.FirstName + " " + user?.LastName}
                      </p>
                      <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                        {user?.Gender},{" "}
                        {new Date().getFullYear() -
                          new Date(user.DOB).getFullYear()}
                      </p>
                    </div>
                    {user.HealthManager === null && (
                      <div className="flex flex-row items-center justify-center space-x-1 ml-2 h-5 px-2 py-0.5 bg-green-50 rounded-2xl">
                        <div className="w-2 h-2 bg-emerald-500 rounded-full animate-pulse" />
                        <div className="text-emerald-500 text-xs font-medium font-poppins leading-none">
                          New
                        </div>
                      </div>
                    )}
                  </div>
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {user?.MedicalConciergeJoiningDate}
                </td>
                <td className="p-4 ">
                  {user?.HealthManager ? (
                    <div className="text-neutral-600 text-sm font-normal font-poppins leading-tight">
                      {user?.HealthManager}
                    </div>
                  ) : (
                    <div className="text-neutral-400 text-sm font-normal font-poppins leading-tight">
                      No yet assigned!
                    </div>
                  )}
                </td>
                <td className="p-4 w-60">
                  {user.Notes === null ? (
                    <button
                      onClick={() => {
                        setSelectedPatient(user);
                        setShowAddNotesModal(true);
                      }}
                      className="text-sky-400 text-sm font-semibold font-poppins leading-none active:scale-95"
                    >
                      Add Notes
                    </button>
                  ) : (
                    <div className="text-neutral-600 text-sm font-normal font-poppins leading-tight line-clamp-2">
                      {user.Notes}
                    </div>
                  )}
                </td>
                <td className="p-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Tooltip message="View Profile">
                      <button
                        onClick={() => {
                          navigate("/patientDashboard/patientProfile", {
                            state: user,
                          });
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    <Tooltip message="Edit">
                      <button
                        onClick={() => {
                          setSelectedPatient(user);
                          fetchPatientProfile(user);
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                    {/* <Tooltip message="Delete">
                      <button className="p-1 rounded-full group group-hover:bg-sky-100">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M9.30078 16.5V10.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M14.3008 16.5V10.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip> */}
                  </div>
                </td>
                <td className="p-4">
                  {user.HealthManager === null ? (
                    <button
                      onClick={() => {
                        setSelectedPatient(user);
                        setShowAssignManagerModal(true);
                      }}
                      className="px-4 py-2 bg-white rounded-lg border border-sky-400 active:scale-95"
                    >
                      <div className="text-sky-400 text-sm font-semibold font-poppins leading-tight">
                        Assign Health Managers
                      </div>
                    </button>
                  ) : user.Request === "Meeting Requested" ? (
                    <div className="flex flex-row items-center space-x-2">
                      <img src={appointment} alt="" />
                      <div className="text-gray-900 text-sm font-normal font-poppins leading-tight">
                        Meeting Requested
                      </div>
                    </div>
                  ) : user.Request === "Callback Requested" ? (
                    <div className="flex flex-row items-center space-x-1">
                      <img src={call} alt="" />
                      <div className="text-gray-900 text-sm font-normal font-poppins leading-tight">
                        Callback Requested
                      </div>
                    </div>
                  ) : (
                    <div className="text-neutral-400 text-sm font-normal font-poppins leading-tight">
                      No requests/remarks yet!
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalItems={filteredUsers.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default ConciergeMembers;
