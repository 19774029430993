import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar, { SidebarItem } from "../components/Sidebar";
import { RxDashboard } from "react-icons/rx";
import { useEffect, useState } from "react";
import { getAllPatients, getArchivedPatients } from "../services/operations/patientManagementAPI";
import { getAllBookedSlots } from "../services/operations/dashboardAPI";
import clinicianDashboard from "../assets/Images/Sidebar/clinicianDashboard.png";
import clinicianDashboardSelected from "../assets/Images/Sidebar/clinicianDashboardSelected.png";
import patientManagement from "../assets/Images/Sidebar/patientManagement.png";
import patientManagementSelected from "../assets/Images/Sidebar/patientManagementSelected.png";
import serviceManagement from "../assets/Images/Sidebar/serviceManagement.png";
import serviceManagementSelected from "../assets/Images/Sidebar/serviceManagementSelected.png";
import operationsManagement from "../assets/Images/Sidebar/operationsManagement.png";
import operationsManagementSelected from "../assets/Images/Sidebar/operationsManagementSelected.png";
import accounting from "../assets/Images/Sidebar/accounting.svg";
import accountingSelected from "../assets/Images/Sidebar/accoutingSelected.svg";
import healthManager from "../assets/Images/Sidebar/healthManager.svg";
import healthManagerSelected from "../assets/Images/Sidebar/healthManagerSelected.svg";
import setting from "../assets/Images/Sidebar/setting.svg";
import settingSelected from "../assets/Images/Sidebar/settingSelected.svg";

import {
  getAllAllergies,
  getAllAllergyCategory,
  getAllDoctors,
  getAllLabPartners,
  getAllMedicines,
  getAllNurses,
  getAllOrganizationUserDetails,
  getAllPreMedicalConditions,
  getAllServicesCardRate,
  getAllSettings,
  getAllSymptomsType,
  getClinicBranches,
  getCustomNotificationList,
  getLabTypes,
  getTelehealthSessionPrice,
} from "../services/operations/masterDataAPI";
import { ACCESS_ROLES, USER_TYPE, hasAccess } from "../utils/constants";
import {
  getAllConciergeUser,
  getAssignedPatients,
  getHealthManagers,
  getHealthManagerSpeciality,
  getMedicalConciergeFeatures,
  getMedicalConciergePlans,
} from "../services/operations/medicalConciergeAPI";
import { getTreatmentCategory } from "../services/operations/ourServicesAPI";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading: authLoading } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);
  const { user, accessRoles } = useSelector((state) => state.profile);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    if (token && user?.UserType !== USER_TYPE.LAB_PARTNER) {
      dispatch(getAllPatients(token));
      dispatch(getArchivedPatients(token));
      dispatch(getAllBookedSlots(token));
      dispatch(getAllServicesCardRate(token));
      dispatch(getAllSettings(token));
      dispatch(getTelehealthSessionPrice(token));
      dispatch(getCustomNotificationList(token));
      dispatch(getAllNurses(token));
      dispatch(getAllDoctors(token));
      dispatch(getAllPreMedicalConditions(token));
      dispatch(getAllAllergies(token));
      dispatch(getAllMedicines(token));
      dispatch(getClinicBranches(token));
      dispatch(getAllLabPartners(token));
      dispatch(getLabTypes(token));
      dispatch(getAllOrganizationUserDetails(token));
      dispatch(getHealthManagers(token));
      dispatch(getHealthManagerSpeciality(token));
      dispatch(getMedicalConciergePlans(token));
      dispatch(getMedicalConciergeFeatures(token));
      dispatch(getAllConciergeUser(token));
      dispatch(getAllAllergyCategory(token));
      dispatch(getAllSymptomsType(token));
    }
  }, [token]);

  useEffect(() => {
    if (token && user?.UserType === USER_TYPE.HEALTH_MANAGER) {
      dispatch(getAssignedPatients(token, user?.DID));
    }
  }, [token]);

  useEffect(() => {
    const fetchTreatmentCategory = async () => {
      if (token) {
        try {
          const res = await dispatch(getTreatmentCategory(token));
        } catch (error) {
          console.error("Failed to fetch treatment category", error);
        }
      }
    };

    fetchTreatmentCategory();
  }, [token, dispatch]);

  useEffect(() => {
    const fetchCoordinates = async () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
      });
    };
    fetchCoordinates();
  }, []);

  useEffect(() => {
    const fetchWeatherData = async () => {
      if (lat !== null && long !== null) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_WEATHER_API_URL}/weather/?lat=${lat}&lon=${long}&units=metric&APPID=${process.env.REACT_APP_WEATHER_API_KEY}`
          );

          if (!response.ok) {
            throw new Error(`Error while fetching weather: ${response.status}`);
          }

          const result = await response.json();
          setWeatherData(result);
        } catch (error) {
          setWeatherData(null);
          console.error("Error while fetching weather:", error);
        }
      }
    };
    fetchWeatherData();
  }, [lat, long]);

  if (authLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (user?.UserType === USER_TYPE.LAB_PARTNER) {
    return (
      <>
        <div className="flex h-full">
          <Sidebar>
            <div className="flex h-full flex-col">
              <div className="flex flex-1 flex-col items-center text-left justify-start">
                <SidebarItem
                  img={clinicianDashboard}
                  imgSelected={clinicianDashboardSelected}
                  text="Dashboard"
                  to="/labPartnerDashboard"
                />
              </div>
            </div>
          </Sidebar>
          <div className="flex flex-col h-screen w-full">
            {weatherData && weatherData.length !== 0 ? (
              <Navbar weatherData={weatherData} />
            ) : (
              <Navbar />
            )}
            <div className="h-[calc(100vh-3.5rem)] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="h-screen">
      {weatherData && weatherData.length !== 0 ? (
        <Navbar weatherData={weatherData} />
      ) : (
        <Navbar />
      )}
      <div className="flex h-[calc(100vh - 4rem)]">
        <Sidebar>
          <div className="flex h-full flex-col">
            <div className="flex flex-1 flex-col items-center text-left justify-start">
              {hasAccess(accessRoles, [
                ACCESS_ROLES.TELEHEALTH_DOCTOR,
                ACCESS_ROLES.DOCTOR,
                ACCESS_ROLES.NURSE,
                ACCESS_ROLES.ADMIN,
              ]) && (
                <SidebarItem
                  img={clinicianDashboard}
                  imgSelected={clinicianDashboardSelected}
                  text="Clinician Dashboard"
                  to="/clinicianDashboard"
                />
              )}
              {hasAccess(accessRoles, [
                ACCESS_ROLES.TELEHEALTH_DOCTOR,
                ACCESS_ROLES.DOCTOR,
                ACCESS_ROLES.NURSE,
                ACCESS_ROLES.ADMIN,
              ]) && (
                <SidebarItem
                  img={patientManagement}
                  imgSelected={patientManagementSelected}
                  text="Patient Management"
                  to="/patientDashboard"
                />
              )}
              {hasAccess(accessRoles, [
                ACCESS_ROLES.ADMIN,
                ACCESS_ROLES.NURSE,
              ]) && (
                <SidebarItem
                  img={serviceManagement}
                  imgSelected={serviceManagementSelected}
                  text="Service Management"
                  to="/serviceManagement"
                />
              )}
              {hasAccess(accessRoles, [
                ACCESS_ROLES.ADMIN,
                ACCESS_ROLES.NURSE,
              ]) && (
                <SidebarItem
                  img={operationsManagement}
                  imgSelected={operationsManagementSelected}
                  text="Operations Management"
                  to="/operationsManagement"
                />
              )}
              {hasAccess(accessRoles, [
                ACCESS_ROLES.ADMIN,
                ACCESS_ROLES.NURSE,
              ]) && (
                <SidebarItem
                  img={accounting}
                  imgSelected={accountingSelected}
                  text="Accounting"
                  to="/invoicing"
                />
              )}
              {/* {hasAccess(accessRoles, [
                ACCESS_ROLES.HEALTH_MANAGER,
              ]) && (
                  <SidebarItem
                    img={healthManager}
                    imgSelected={healthManagerSelected}
                    text="Health Manager"
                    to="/healthManagerDashboard"
                  />
                )} */}
              {user?.UserType === USER_TYPE.HEALTH_MANAGER && (
                <SidebarItem
                  img={healthManager}
                  imgSelected={healthManagerSelected}
                  text="Health Manager"
                  to="/healthManagerDashboard"
                />
              )}
            </div>
            {hasAccess(accessRoles, [
              ACCESS_ROLES.ADMIN,
              ACCESS_ROLES.NURSE,
            ]) && (
              <SidebarItem
                img={setting}
                imgSelected={settingSelected}
                text="Settings"
                to="/settings"
              />
            )}
          </div>
        </Sidebar>
        <div className="flex flex-col h-full w-full">
          <div className="h-[calc(100vh-4rem)] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
