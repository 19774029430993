import { toast } from "react-hot-toast";
import {
  setAccessRoles,
  setLoading,
  setLogo,
  setUser,
  setUserType,
} from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector";
import { userEndpoints } from "../apis";
import { logout } from "./authAPI";
// import { USER_TYPE } from "../../utils/constants";
import { getBucketURL } from "../../utils/regex";

const { GET_USER_DETAILS_API, GET_ORGANIZATION_LOGO } = userEndpoints;

// VALIDATE USER
export function getUserDetails(token, navigate) {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_USER_DETAILS_API, null, {
        Authorization: `Bearer ${token}`,
      });
      console.log("Validating User 🥷🏻", response?.status);
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const userImage = response?.data?.user?.image
        ? response?.data?.user?.image
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.Name}`;
      dispatch(setUser({ ...response?.data?.user, image: userImage }));
      dispatch(setAccessRoles(response?.data?.user?.AccessRoles));
    } catch (error) {
      dispatch(logout(navigate));
      console.log("GET_USER_DETAILS API ERROR............", error);
      toast.error("Session Expired");
    }
  };
}

//GET ORGANIZATION LOGO
export function getOrganizationLogo() {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_ORGANIZATION_LOGO, null, {
        // Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setLogo(response?.data?.LogoURL));
    } catch (error) {
      console.log("GET_ORGANIZATION_LOGO API ERROR............", error);
    }
  };
}
