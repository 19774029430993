import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Rate } from "antd";
import Tooltip from "./Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { deleteNurse } from "../services/operations/masterDataAPI";
import deleteIcon from "../assets/Images/MedicalConcierge/deleteRedIcon.svg";
import ModalComponent from "./Modal";

function NurseCardView({ nurses }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const handleDelete = async () => {
    dispatch(deleteNurse(token, selectedDoctor.DID));
    setShowDeleteModal(false);
  };
  return (
    <>
      {/* Delete Modal Component */}
      <ModalComponent
        show={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
          setSelectedDoctor(null);
        }}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteIcon} alt="" />
          </div>
          <div className="text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Remove '{selectedDoctor?.Name}'?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            This will remove {selectedDoctor?.Name}. from the nurses's list of
            your clinic.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={() => {
                setShowDeleteModal(false);
                setSelectedDoctor(null);
              }}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleDelete}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Remove
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
        outSideModalPress={false}
      >
        <div className="text-slate-800 text-2xl font-semibold font-poppins leading-loose">
          Nurse's Profile
        </div>
        <div className="h-px bg-gray-200 my-3" />
        <div className="flex items-center">
          <img
            src={selectedDoctor?.Image}
            className="w-24 h-24 rounded-full mr-3 object-cover"
          />
          <div className="flex flex-col space-y-1">
            <div className="text-zinc-900 text-sm font-semibold font-poppins leading-tight">
              {selectedDoctor?.Name}
            </div>
            <div className="text-zinc-500 text-xs font-normal font-poppins">
              ID : {selectedDoctor?.DID}
            </div>
          </div>
        </div>
        <div className="container w-[30vw] mx-auto mt-2 py-4 px-6 shadow rounded-md">
          {/* Joining Date */}
          <div className="flex flex-row items-center">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Joining Date
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              {moment(selectedDoctor?.JoiningDate).format("Do MMMM YYYY")}
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />

          {/* Contact Details */}
          <div className="flex flex-row items-center">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Contact
            </div>
            <div className="flex-1 space-y-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              <p>{selectedDoctor?.Phone}</p>
              <p>{selectedDoctor?.Email}</p>
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />

          {/* Speciality */}
          <div className="flex flex-row items-center border-b-1 border-gray-100">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Speciality & Exp.
            </div>
            <div className="flex-1 space-y-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              <p>{selectedDoctor?.RelatedSpecialities[0]?.Speciality}</p>
              <p>({selectedDoctor?.Experience} yrs exp.)</p>
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <div className="flex flex-row items-center border-b-1 border-gray-100">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Languages
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              {selectedDoctor?.RelatedLanguages?.map((language) => {
                return language?.Name;
              }).join(", ")}
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <div className="flex flex-row items-center">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Working Hours
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              {selectedDoctor?.WorkingHours} hrs/week
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            setShowProfileModal(false);
            navigate("addNewNurse", {
              state: selectedDoctor,
            });
          }}
          className="px-3 py-2 mt-4 rounded-lg shadow border border-sky-500 flex items-center justify-center space-x-2"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.0149 5.23239L17.4166 3.83063C18.1908 3.05646 19.446 3.05646 20.2202 3.83063C20.9943 4.6048 20.9943 5.85998 20.2202 6.63415L18.8184 8.03591M16.0149 5.23239L10.781 10.4663C9.73571 11.5116 9.21304 12.0342 8.85715 12.6711C8.50125 13.308 8.14318 14.8119 7.80078 16.25C9.23887 15.9076 10.7428 15.5495 11.3797 15.1936C12.0166 14.8377 12.5392 14.3151 13.5845 13.2698L18.8184 8.03591M16.0149 5.23239L18.8184 8.03591"
              stroke="#3CA5DC"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.8008 12.25C20.8008 16.4926 20.8008 18.614 19.4828 19.932C18.1648 21.25 16.0434 21.25 11.8008 21.25C7.55814 21.25 5.43682 21.25 4.1188 19.932C2.80078 18.614 2.80078 16.4926 2.80078 12.25C2.80078 8.00736 2.80078 5.88604 4.1188 4.56802C5.43682 3.25 7.55814 3.25 11.8008 3.25"
              stroke="#3CA5DC"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <div className="text-sky-400 text-sm font-semibold font-poppins">
            Edit Profile
          </div>
        </button>
      </ModalComponent>
      <div className="mt-6 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {nurses.map((doc) => (
          <div
            key={doc.DID}
            className="flex flex-col p-4 bg-neutral-50 rounded-2xl shadow"
          >
            <div className="flex items-center justify-between">
              <div
                className="flex items-center space-x-3 cursor-pointer"
                onClick={() => {
                  setSelectedDoctor(doc);
                  setShowProfileModal(true);
                }}
              >
                <img
                  src={doc.Image}
                  alt="PatientPic"
                  className="w-10 h-10 rounded-full object-cover"
                />
                <div className="flex flex-col">
                  <div className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                    {doc.Name}
                  </div>
                  <div className="text-neutral-600 text-sm font-normal font-poppins leading-tight ">
                    DID : {doc.DID}
                  </div>
                </div>
              </div>
              {/* <Rate
                allowHalf
                disabled
                style={{
                  fontSize: "1rem",
                }}
                defaultValue={doc.Rating}
              /> */}
            </div>
            <div className="h-px bg-gray-200 my-4" />
            <div className="flex justify-between">
              <div className="text-neutral-600 text-sm font-normal leading-tight font-poppins">
                Joining Date
              </div>
              <div className="text-neutral-600 text-sm font-medium leading-tight">
                {moment(doc.dateOfJoining).format("DD MMMM YYYY")}
              </div>
            </div>
            <div className="h-px bg-gray-200 my-4" />
            <div className="flex justify-between">
              <div className="text-neutral-600 text-sm font-normal leading-tight font-poppins">
                Speciality
              </div>
              <div className="text-neutral-600 text-sm font-medium leading-tight">
                {doc?.RelatedSpecialities[0]?.Speciality}...
              </div>
            </div>
            <div className="h-px bg-gray-200 my-4" />
            <div className="flex justify-between">
              <div className="text-neutral-600 text-sm font-normal leading-tight font-poppins">
                Working Hrs
              </div>
              <div className="text-neutral-600 text-sm font-medium leading-tight">
                {doc.WorkingHours || 0} hrs/week
              </div>
            </div>
            <div className="h-px bg-gray-200 my-4" />
            <div className="flex flex-row items-center space-x-2">
              <Tooltip message="Manage Availability">
                <button
                  onClick={() => {
                    setSelectedDoctor(doc);
                    setShowProfileModal(true);
                  }}
                  className="p-1 rounded-full group group-hover:bg-sky-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                  >
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M12 8V12L14 14"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </Tooltip>
              <Tooltip message="View Profile">
                <button
                  onClick={() => {
                    setSelectedDoctor(doc);
                    setShowProfileModal(true);
                  }}
                  className="p-1 rounded-full group group-hover:bg-sky-100"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                  >
                    <path
                      d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                    <path
                      d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </Tooltip>

              <Tooltip message="Manage">
                <button
                  onClick={() => {
                    navigate("addNewNurse", {
                      state: doc,
                    });
                  }}
                  className="p-1 rounded-full group group-hover:bg-sky-100"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                  >
                    <path
                      d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
              </Tooltip>

              <Tooltip message="Delete">
                <button
                  onClick={() => {
                    setSelectedDoctor(doc);
                    setShowDeleteModal(true);
                  }}
                  className="p-1 rounded-full group group-hover:bg-sky-100"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                  >
                    <path
                      d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M9.30078 16.5V10.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M14.3008 16.5V10.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default NurseCardView;
