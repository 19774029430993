import { useNavigate } from "react-router-dom";
import Tooltip from "./Tooltip";
import { changeConciergePlan } from "../services/operations/patientManagementAPI";
import { useDispatch, useSelector } from "react-redux";

const MedicalConceirgePlanCard = ({
  plan,
  currentPlan,
  patient,
  showEditButton = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const handleChangePlan = async (p) => {
    const data = {
      AHUID: patient?.AHUID,
      MCPID: p?.MCPID.toString(),
    };
    dispatch(changeConciergePlan(token, data, navigate, patient));
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6 w-full max-w-6xl">
      <div className="flex justify-between">
        <div className="flex-1 flex items-center space-x-4">
          <div className="flex-1">
            <p className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
              Plan Name
            </p>
            <p className="text-neutral-600 text-sm font-normal font-poppins leading-tight mt-2 truncate">
              {plan.Name}
            </p>
          </div>
          <div className="flex-1">
            <p className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
              Plan Type
            </p>
            <p className="text-neutral-600 text-sm font-normal font-poppins leading-tight mt-2">
              {plan.PlanType}
            </p>
          </div>
          <div className="flex-1">
            <p className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
              Price
            </p>
            <p className="text-neutral-600 text-sm font-normal font-poppins leading-tight mt-2">
              {plan.Price} CHF
            </p>
          </div>
          <div className="flex-1">
            <p className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
              Members
            </p>
            <p className="text-neutral-600 text-sm font-normal font-poppins leading-tight mt-2">
              {plan.MembersIncluded}
            </p>
          </div>
          <div className="flex-1">
            <p className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
              Interactions
            </p>
            <p className="text-neutral-600 text-sm font-normal font-poppins leading-tight mt-2">
              {plan.Interactions}
            </p>
          </div>
        </div>

        {showEditButton ? (
          <div className="flex-1 flex justify-end">
            <Tooltip message="Edit">
              <button
                onClick={() => {
                  navigate("addNewConciergePlan", {
                    state: plan,
                  });
                }}
                className="p-1 rounded-full group group-hover:bg-sky-100"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                >
                  <path
                    d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        ) : (
          <div className="flex-1 flex justify-end">
            {currentPlan?.Plan?.MCPID === plan?.MCPID ? (
              <div className="px-3 py-1 bg-purple-50 rounded-2xl text-center text-zinc-900 text-sm font-semibold font-poppins leading-tight my-5">
                Current Plan
              </div>
            ) : (
              <button
                onClick={() => handleChangePlan(plan)}
                className="rounded-lg px-4 py-2 bg-white border border-sky-400 active:scale-95"
              >
                <div className="text-sky-400 text-base font-semibold font-poppins">
                  Change Plan
                </div>
              </button>
            )}
          </div>
        )}
      </div>

      <div className="border border-b-1 border-gray-100 my-4" />
      <p className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
        Cover Details
      </p>
      <p className="text-neutral-600 text-sm font-normal font-poppins leading-tight mt-2">
        {plan.CoverDetails}
      </p>
      <div className="border border-b-1 border-gray-100 my-4"></div>
      <div>
        <p className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
          Features Included
        </p>
        <div className="w-2/3 flex flex-wrap gap-2 mt-2">
          {plan?.FeaturesPerPlans?.map((feature, index) => (
            <span
              key={index}
              className="bg-gray-100 rounded-3xl px-4 py-2.5 text-center text-zinc-900 text-sm font-normal font-poppins"
            >
              {feature.Feature}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MedicalConceirgePlanCard;
