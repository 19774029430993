import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft, FaHeartPulse } from "react-icons/fa6";
import TelehealthDataBg from "../../assets/Images/TelehealthData/TelehealthDataBg.png";
import telehealthDataIcon from "../../assets/Images/TelehealthData/telehealthDataIcon.png";
import notificationIcon from "../../assets/Images/TelehealthData/notificationIcon.png";
import deactivateStatus from "../../assets/Images/RewardManagement/deactivateStatus.png";
import activateStatus from "../../assets/Images/RewardManagement/activateStatus.png";
import labTestIcon from "../../assets/Images/RewardManagement/labTestIcon.png";
import treatmentIcon from "../../assets/Images/RewardManagement/treatmentIcon.png";
import merchandiseIcon from "../../assets/Images/RewardManagement/merchandiseIcon.png";
import editIcon from "../../assets/Images/RewardManagement/editIcon.png";
import binIcon from "../../assets/Images/OurServices/binIcon.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AHlogo from "../../assets/Images/logo.png";
import Breadcrumb from "../../components/Breadcrumb";
import qrCodeImg from "../../assets/Images/Invoicing/qrCodeImg.png";
import { IoIosSearch } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ModalComponent from "../../components/Modal";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";
import Tooltip from "../../components/Tooltip";
import { FaCheckCircle } from "react-icons/fa";
import { updateRewardOffer } from "../../services/operations/rewardManagementAPI";
import alertIcon from "../../assets/Images/RewardManagement/alertIcon.png";
import DropdownForTelehealthData from "../../components/Dropdown/DropdownForTelehealthData";
import { FaBell } from "react-icons/fa";
import { updateTelehealthSessionPrice } from "../../services/operations/masterDataAPI";

function TelehealthData() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const { telehealthPricing } = useSelector((state) => state.masterData);
    const [isLoading, setIsLoading] = useState(false);
    const [editingField, setEditingField] = useState(null);
    const [priceOneSession, setPriceOneSession] = useState(null);
    const [OneSession, setOneSession] = useState(null);
    const [priceTwelveSessions, setPriceTwelveSessions] = useState(null);
    const [TwelveSessions, setTwelveSessions] = useState(null);
    const [selectedScopeOfAppNotification, setSelectedScopeOfAppNotification] = useState("Concierge Members");
    const [selectedTimeOfAppNotification, setSelectedTimeOfAppNotification] = useState("24 Hours Before");
    const [selectedScopeOfWebNotification, setSelectedScopeOfWebNotification] = useState("Concierge Members");
    const [selectedTimeOfWebNotification, setSelectedTimeOfWebNotification] = useState("24 Hours Before");

    const [newPriceOneSession, setNewPriceOneSession] = useState(null);
    const [newPriceTwelveSessions, setNewPriceTwelveSessions] = useState(null);

    // Handle dropdown selection
    const handleSelectScopeOfNotification = () => {
        setEditingField(null); // Disable edit mode
    };

    const handleSelectTimeOfNotification = () => {
        setEditingField(null); // Disable edit mode
    };

    const handleSaveOneSession = async () => {
        if (newPriceOneSession !== priceOneSession) {
            const body = {
                TSPID: telehealthPricing?.find((item) => item?.TotalSessions === 1)?.TSPID,
                Name: "Cost for 1 TeleHealth Session",
                TotalSessions: 1,
                Price: newPriceOneSession,
            };

            try {
                await dispatch(updateTelehealthSessionPrice(token, body));
                setPriceOneSession(newPriceOneSession);
            } catch (error) {
                console.error("Error updating Telehealth Session Price for 1 session:", error);
            }
        }
        setEditingField(null);
    };

    const handleSaveTwelveSessions = async () => {
        if (newPriceTwelveSessions !== priceTwelveSessions) {
            const body = {
                TSPID: telehealthPricing?.find((item) => item?.TotalSessions === 12)?.TSPID,
                Name: "Cost for 12 TeleHealth Sessions",
                TotalSessions: 12,
                Price: newPriceTwelveSessions,
            };

            try {
                await dispatch(updateTelehealthSessionPrice(token, body));
                setPriceTwelveSessions(newPriceTwelveSessions);
            } catch (error) {
                console.error("Error updating Telehealth Session Price for 12 sessions:", error);
            }
        }
        setEditingField(null);
    };

    const handleEditField = (field) => {
        // If another field is being edited, make it non-editable
        if (editingField !== field) {
            setEditingField(field);
        } else {
            setEditingField(null);
        }
    };

    useEffect(() => {
        if (telehealthPricing?.length > 0) {
            const oneSessionPricing = telehealthPricing?.find(item => item?.TotalSessions === 1);
            const twelveSessionsPricing = telehealthPricing?.find(item => item?.TotalSessions === 12);

            if (oneSessionPricing) {
                setPriceOneSession(oneSessionPricing?.Price);
                setNewPriceOneSession(oneSessionPricing?.Price);
            }

            if (twelveSessionsPricing) {
                setPriceTwelveSessions(twelveSessionsPricing?.Price);
                setNewPriceTwelveSessions(twelveSessionsPricing?.Price);
            }
        }
    }, [telehealthPricing]);

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            {/* HEADER */}
            <div className="flex flex-row justify-between items-center">
                {/* TITLE */}
                <div className="flex flex-row items-center w-full">
                    <div className="flex flex-col justify-center items-start w-full gap-1">
                        <div className="flex flex-row justify-between items-center w-full">
                            <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                                TeleHealth Data
                            </h1>
                        </div>
                        <Breadcrumb />
                    </div>
                </div>
            </div>

            <div className="flex flex-row justify-between items-center w-full mt-6">
                {/* Left Content */}
                <div className="flex flex-col justify-start items-start w-1/2">
                    {/* Telehealth */}
                    <div className="flex flex-col justify-center items-start w-full bg-[#FAFAFA] rounded-xl shadow py-9 pl-11 pr-6">
                        <h1 className="text-[#263238] text-base font-semibold font-poppins leading-6">
                            Set Up Price for TeleHealth
                        </h1>

                        {/* 1 Session */}
                        <div className="flex flex-row justify-center items-start w-full mt-6">
                            <img
                                src={telehealthDataIcon}
                                className="w-8 h-w-8 object-cover rounded-full"
                                alt="Telehealth Data Icon"
                            />
                            <div className="w-60 flex flex-col justify-center items-start ml-3">
                                <h1 className="text-[#263238] text-xs font-normal font-poppins leading-[18px]">
                                    Cost for 1 TeleHealth Session
                                </h1>
                                <h1 className="text-[#8A8A8A] text-[8px] font-normal font-poppins leading-3">
                                    (If not maintained, users will be offered without payment option for TeleHealth)
                                </h1>
                            </div>
                            <div className={`w-40 flex flex-row justify-start items-center rounded-sm border p-2 bg-[#F3F5F7] ml-[34px] mr-20 ${editingField === 'oneSession' ? "border-sky-200" : "border-[#34405433]"}`}>
                                {editingField === 'oneSession' ? (
                                    <input
                                        autoFocus
                                        type="number"
                                        value={newPriceOneSession}
                                        onChange={(e) => setNewPriceOneSession(e.target.value)}
                                        className="w-full bg-transparent text-[#545454] text-[10px] outline-none leading-[15px] font-normal font-poppins"
                                    />
                                ) : (
                                    <h1 className="text-[#545454] text-[10px] leading-[15px] font-normal font-poppins">CHF {priceOneSession}</h1>
                                )}
                            </div>
                            <button
                                onClick={() => { handleEditField('oneSession') }}
                                className=""
                            >
                                <Tooltip message="Edit">
                                    <div className="p-1 rounded-full group group-hover:bg-sky-100">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 h-5 text-[#8A8A8A] group-hover:text-sky-400"
                                        >
                                            <path
                                                d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                </Tooltip>
                            </button>
                            <button
                                onClick={handleSaveOneSession}
                                disabled={editingField !== 'oneSession'} className={`w-32 flex flex-row justify-center items-center rounded-sm border ${editingField === 'oneSession' ? "border-[#3CA5DC] bg-[#E5F2FF]" : "border-[#34405433] bg-[#F3F5F7]"} p-1 px-2 ml-7 hover:scale-95`}>
                                <h1 className={`${editingField === 'oneSession' ? "text-[#3CA5DC] font-medium" : "text-[#8A8A8A] font-normal"} text-[10px] font-poppins leading-[15px]`}>
                                    Save Changes
                                </h1>
                            </button>
                        </div>

                        {/* 12 Sessions */}
                        <div className="flex flex-row justify-start items-center w-full mt-8">
                            <img
                                src={telehealthDataIcon}
                                className="w-8 h-w-8 object-cover rounded-full"
                                alt="Telehealth Data Icon"
                            />
                            <div className="w-60 flex flex-col justify-center items-start ml-3">
                                <h1 className="text-[#263238] text-xs font-normal font-poppins leading-[18px]">
                                    Cost for 12 TeleHealth Sessions
                                </h1>
                                <h1 className="text-[#8A8A8A] text-[8px] font-normal font-poppins leading-3">
                                    (if not maintained, users will be offered price for only 1 session)
                                </h1>
                            </div>
                            <div className={`w-40 flex flex-row justify-start items-center rounded-sm border p-2 bg-[#F3F5F7] ml-[34px] mr-20 ${editingField === 'twelveSessions' ? "border-sky-200" : "border-[#34405433]"}`}>
                                {editingField === 'twelveSessions' ? (
                                    <input
                                        autoFocus
                                        type="number"
                                        value={newPriceTwelveSessions}
                                        onChange={(e) => setNewPriceTwelveSessions(e.target.value)}
                                        className="w-full bg-transparent text-[#545454] text-[10px] outline-none leading-[15px] font-normal font-poppins"
                                    />
                                ) : (
                                    <h1 className="text-[#545454] text-[10px] leading-[15px] font-normal font-poppins">CHF {priceTwelveSessions}</h1>
                                )}
                            </div>
                            <button
                                onClick={() => { handleEditField('twelveSessions') }}
                                className=""
                            >
                                <Tooltip message="Edit">
                                    <div className="p-1 rounded-full group group-hover:bg-sky-100">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 h-5 text-[#8A8A8A] group-hover:text-sky-400"
                                        >
                                            <path
                                                d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                </Tooltip>
                            </button>
                            <button
                                onClick={handleSaveTwelveSessions}
                                disabled={editingField !== 'twelveSessions'} className={`w-32 flex flex-row justify-center items-center rounded-sm border ${editingField === 'twelveSessions' ? "border-[#3CA5DC] bg-[#E5F2FF]" : "border-[#34405433] bg-[#F3F5F7]"} p-1 px-2 ml-7 hover:scale-95`}>
                                <h1 className={`${editingField === 'twelveSessions' ? "text-[#3CA5DC] font-medium" : "text-[#8A8A8A] font-normal"} text-[10px] font-poppins leading-[15px]`}>
                                    Save Changes
                                </h1>
                            </button>
                        </div>
                    </div>

                    {/* In-App Notifications */}
                    <div className="flex flex-col justify-center items-start w-full bg-[#FAFAFA] rounded-xl shadow py-9 pl-11 pr-6 mt-7">
                        <div className="flex flex-row justify-between items-center w-full">
                            <h1 className="text-[#263238] text-base font-semibold font-poppins leading-6">
                                Manage In-App Notifications
                            </h1>
                            <button
                                onClick={() => { navigate("/serviceManagement/notificationManagement/customNotification", {state: {navigateTo: "/serviceManagement/telehealthData"}}) }}
                                className={`w-44 flex flex-row justify-center items-center rounded-[4px] bg-[#3CA5DC] p-2 gap-1 hover:scale-95`}>
                                <FaBell className="w-6 h-6 text-white" />
                                <h1 className={`text-[#FFFFFF] font-medium text-[10px] font-poppins leading-[15px]`}>
                                    Send Custom Notification
                                </h1>
                            </button>
                        </div>

                        {/* Scope of Notification */}
                        <div className="flex flex-row justify-center items-start w-full mt-6">
                            <img
                                src={notificationIcon}
                                className="w-8 h-w-8 object-cover rounded-full"
                                alt="Notification Icon"
                            />
                            <div className="w-60 flex flex-col justify-center items-start ml-3">
                                <h1 className="text-[#263238] text-xs font-normal font-poppins leading-[18px]">
                                    Scope of Notification
                                </h1>
                                <h1 className="text-[#8A8A8A] text-[8px] font-normal font-poppins leading-3">
                                    (Who will receive Notifications)
                                </h1>
                            </div>

                            {editingField === 'scopeAppNotification' ? (
                                <div className="w-40 flex flex-row justify-start items-center ml-[34px] mr-20">
                                    <div className="w-full">
                                        <DropdownForTelehealthData
                                            placeholder="Select Scope"
                                            name="ScopeOfAppNotification"
                                            options={["Concierge Members", "All Patients", "Premium Users"]}
                                            onSelect={(name, option) => { setSelectedScopeOfAppNotification(option); }}
                                            value={selectedScopeOfAppNotification}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="w-40 flex flex-row justify-start items-center rounded-sm border border-[#34405433] p-2 bg-[#F3F5F7] ml-[34px] mr-20">
                                    <h1 className="text-[#545454] text-[10px] font-normal font-poppins leading-[15px]">
                                        {selectedScopeOfAppNotification}
                                    </h1>
                                </div>
                            )}
                            <button
                                onClick={() => { handleEditField('scopeAppNotification') }}
                                className=""
                            >
                                <Tooltip message="Edit">
                                    <div className="p-1 rounded-full group group-hover:bg-sky-100">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 h-5 text-[#8A8A8A] group-hover:text-sky-400"
                                        >
                                            <path
                                                d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                </Tooltip>
                            </button>
                            <button
                                onClick={handleSelectScopeOfNotification}
                                disabled={editingField !== 'scopeAppNotification'} className={`w-32 flex flex-row justify-center items-center rounded-sm border ${editingField === 'scopeAppNotification' ? "border-[#3CA5DC] bg-[#E5F2FF]" : "border-[#34405433] bg-[#F3F5F7]"} p-1 px-2 ml-7 hover:scale-95`}>
                                <h1 className={`${editingField === 'scopeAppNotification' ? "text-[#3CA5DC] font-medium" : "text-[#8A8A8A] font-normal"} text-[10px] font-poppins leading-[15px]`}>
                                    Save Changes
                                </h1>
                            </button>
                        </div>

                        {/* Time of Notification */}
                        <div className="flex flex-row justify-start items-center w-full mt-8">
                            <img
                                src={notificationIcon}
                                className="w-8 h-w-8 object-cover rounded-full"
                                alt="Notification Icon"
                            />
                            <div className="w-60 flex flex-col justify-center items-start ml-3">
                                <h1 className="text-[#263238] text-xs font-normal font-poppins leading-[18px]">
                                    Time of Notification
                                </h1>
                                <h1 className="text-[#8A8A8A] text-[8px] font-normal font-poppins leading-3">
                                    (What time reminder notification should be sent to patients)
                                </h1>
                            </div>
                            {editingField === 'timeAppNotification' ? (
                                <div className="w-40 flex flex-row justify-start items-center ml-[34px] mr-20">
                                    <div className="w-full">
                                        <DropdownForTelehealthData
                                            placeholder="Select Time"
                                            name="TimeOfAppNotification"
                                            options={["24 Hours Before", "12 Hours Before", "06 Hours Before", "01 Hour Before"]}
                                            onSelect={(name, option) => {
                                                setSelectedTimeOfAppNotification(option);
                                            }}
                                            value={selectedTimeOfAppNotification}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="w-40 flex flex-row justify-start items-center rounded-sm border border-[#34405433] p-2 bg-[#F3F5F7] ml-[34px] mr-20">
                                    <h1 className="text-[#545454] text-[10px] font-normal font-poppins leading-[15px]">
                                        {selectedTimeOfAppNotification}
                                    </h1>
                                </div>
                            )}
                            <button
                                onClick={() => { handleEditField('timeAppNotification') }}
                                className=""
                            >
                                <Tooltip message="Edit">
                                    <div className="p-1 rounded-full group group-hover:bg-sky-100">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 h-5 text-[#8A8A8A] group-hover:text-sky-400"
                                        >
                                            <path
                                                d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                </Tooltip>
                            </button>
                            <button
                                onClick={handleSelectTimeOfNotification}
                                disabled={editingField !== 'timeAppNotification'} className={`w-32 flex flex-row justify-center items-center rounded-sm border ${editingField === 'timeAppNotification' ? "border-[#3CA5DC] bg-[#E5F2FF]" : "border-[#34405433] bg-[#F3F5F7]"} p-1 px-2 ml-7 hover:scale-95`}>
                                <h1 className={`${editingField === 'timeAppNotification' ? "text-[#3CA5DC] font-medium" : "text-[#8A8A8A] font-normal"} text-[10px] font-poppins leading-[15px]`}>
                                    Save Changes
                                </h1>
                            </button>
                        </div>
                    </div>

                    {/* Manage Web Notifications */}
                    {/* <div className="flex flex-col justify-center items-start w-full bg-[#FAFAFA] rounded-xl shadow py-9 pl-11 pr-6 mt-7">
                        <h1 className="text-[#263238] text-base font-semibold font-poppins leading-6">
                            Manage Web Notifications
                        </h1>

                        <div className="flex flex-row justify-center items-start w-full mt-6">
                            <img
                                src={notificationIcon}
                                className="w-8 h-w-8 object-cover rounded-full"
                                alt="Notification Icon"
                            />
                            <div className="w-60 flex flex-col justify-center items-start ml-3">
                                <h1 className="text-[#263238] text-xs font-normal font-poppins leading-[18px]">
                                    Scope of Notification
                                </h1>
                                <h1 className="text-[#8A8A8A] text-[8px] font-normal font-poppins leading-3">
                                    (Who will receive Notifications)
                                </h1>
                            </div>
                            {editingField === 'scopeWebNotification' ? (
                                <div className="w-40 flex flex-row justify-start items-center ml-[34px] mr-20">
                                    <div className="w-full">
                                        <DropdownForTelehealthData
                                            placeholder="Select Scope"
                                            name="ScopeOfWebNotification"
                                            options={["Concierge Members", "All Patients", "Premium Users"]}
                                            onSelect={(name, option) => { setSelectedScopeOfWebNotification(option); }}
                                            value={selectedScopeOfWebNotification}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="w-40 flex flex-row justify-start items-center rounded-sm border border-[#34405433] p-2 bg-[#F3F5F7] ml-[34px] mr-20">
                                    <h1 className="text-[#545454] text-[10px] font-normal font-poppins leading-[15px]">
                                        {selectedScopeOfWebNotification}
                                    </h1>
                                </div>
                            )}
                            <button
                                onClick={() => { handleEditField('scopeWebNotification') }}
                                className=""
                            >
                                <Tooltip message="Edit">
                                    <div className="p-1 rounded-full group group-hover:bg-sky-100">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 h-5 text-[#8A8A8A] group-hover:text-sky-400"
                                        >
                                            <path
                                                d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                </Tooltip>
                            </button>
                            <button
                                onClick={handleSelectScopeOfNotification}
                                disabled={editingField !== 'scopeWebNotification'} className={`w-32 flex flex-row justify-center items-center rounded-sm border ${editingField === 'scopeWebNotification' ? "border-[#3CA5DC] bg-[#E5F2FF]" : "border-[#34405433] bg-[#F3F5F7]"} p-1 px-2 ml-7 hover:scale-95`}>
                                <h1 className={`${editingField === 'scopeWebNotification' ? "text-[#3CA5DC] font-medium" : "text-[#8A8A8A] font-normal"} text-[10px] font-poppins leading-[15px]`}>
                                    Save Changes
                                </h1>
                            </button>
                        </div>

                        <div className="flex flex-row justify-start items-center w-full mt-8">
                            <img
                                src={notificationIcon}
                                className="w-8 h-w-8 object-cover rounded-full"
                                alt="Notification Icon"
                            />
                            <div className="w-60 flex flex-col justify-center items-start ml-3">
                                <h1 className="text-[#263238] text-xs font-normal font-poppins leading-[18px]">
                                    Time of Notification
                                </h1>
                                <h1 className="text-[#8A8A8A] text-[8px] font-normal font-poppins leading-3">
                                    (What time reminder notification should be sent to patients)
                                </h1>
                            </div>
                            {editingField === 'timeWebNotification' ? (
                                <div className="w-40 flex flex-row justify-start items-center ml-[34px] mr-20">
                                    <div className="w-full">
                                        <DropdownForTelehealthData
                                            placeholder="Select Time"
                                            name="TimeOfWebNotification"
                                            options={["24 Hours Before", "12 Hours Before", "06 Hours Before", "01 Hour Before"]}
                                            onSelect={(name, option) => {
                                                setSelectedTimeOfWebNotification(option);
                                            }}
                                            value={selectedTimeOfWebNotification}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="w-40 flex flex-row justify-start items-center rounded-sm border border-[#34405433] p-2 bg-[#F3F5F7] ml-[34px] mr-20">
                                    <h1 className="text-[#545454] text-[10px] font-normal font-poppins leading-[15px]">
                                        {selectedTimeOfWebNotification}
                                    </h1>
                                </div>
                            )}
                            <button
                                onClick={() => { handleEditField('timeWebNotification') }}
                                className=""
                            >
                                <Tooltip message="Edit">
                                    <div className="p-1 rounded-full group group-hover:bg-sky-100">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 h-5 text-[#8A8A8A] group-hover:text-sky-400"
                                        >
                                            <path
                                                d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                </Tooltip>
                            </button>
                            <button
                                onClick={handleSelectTimeOfNotification}
                                disabled={editingField !== 'timeWebNotification'} className={`w-32 flex flex-row justify-center items-center rounded-sm border ${editingField === 'timeWebNotification' ? "border-[#3CA5DC] bg-[#E5F2FF]" : "border-[#34405433] bg-[#F3F5F7]"} p-1 px-2 ml-7 hover:scale-95`}>
                                <h1 className={`${editingField === 'timeWebNotification' ? "text-[#3CA5DC] font-medium" : "text-[#8A8A8A] font-normal"} text-[10px] font-poppins leading-[15px]`}>
                                    Save Changes
                                </h1>
                            </button>
                        </div>
                    </div> */}
                </div>

                {/* Right Content */}
                <div className="flex flex-row justify-center items-center w-1/2">
                    <img
                        src={TelehealthDataBg}
                        className="w-[500px] h-[500px] object-contain"
                        alt="Telehealth Data Background"
                    />
                </div>
            </div>
        </div>
    );
}

export default TelehealthData;