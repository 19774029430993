import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tooltip from "./Tooltip";
import ModalComponent from "./Modal";
import Dropdown from "./Dropdown";
import deleteIcon from "../assets/Images/MedicalConcierge/deleteRedIcon.svg";
import noPatientFound from "../assets/Images/PatientDashboard/noPatientFound.png";
import medicalCommitteeSelectedIcon from "../assets/Images/PatientProfile/TabBar/medicalCommitteeSelected.svg";
import doctorIcon from "../assets/Images/PatientProfile/MedicalCommittee/doctorIcon.png";
import nurseIcon from "../assets/Images/PatientProfile/MedicalCommittee/nurseIcon.png";
import healthManagerIcon from "../assets/Images/PatientProfile/MedicalCommittee/healthManagerIcon.png";
import noMatchFound from "../assets/Images/noMatchFound.svg";
import {
  addMedicalCommitteeMember,
  deleteMedicalCommitteeMember,
  updateMedicalCommitteeMember,
} from "../services/operations/patientManagementAPI";
import toast from "react-hot-toast";
import {
  setMedicalCommittee,
  updateMedicalCommittee,
} from "../slices/patientSlice";
import Pagination from "./Pagination";
import { ACCESS_ROLES, hasAccess } from "../utils/constants";

function MedicalCommittee({ hasEditAccess, patient, medicalCommitteeMembers }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { allOrganizationUsers, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const { user, accessRoles } = useSelector((state) => state.profile);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [showHealthManagerList, setShowHealthManagerList] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [searchInput, setSearchInput] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [showDropDown, setShowDropDown] = useState(null);
  const [members, setMembers] = useState([{ Doc: null, Role: "" }]);
  const [errors, setErrors] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const itemsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredMembers = medicalCommitteeMembers?.filter((user) => {
    return user?.Name?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredMembers.slice(indexOfFirstItem, indexOfLastItem);

  // Function to filter health managers based on search input for each field
  const filteredHealthManagers = allOrganizationUsers.filter((manager) =>
    manager?.Name?.toLowerCase().includes(
      searchInput[currentIndex]?.toLowerCase()
    )
  );

  const handleAddMore = () => {
    setMembers([...members, { Doc: null, Role: "" }]);
    setErrors([...errors, { Doc: false, Role: false }]);
  };

  // Function to handle search input change for each field
  const handleSearchInputChange = (index, value) => {
    setCurrentIndex(index);
    if (value === "") {
      setShowHealthManagerList(false);
    }
    if (value.length > 1) {
      setShowHealthManagerList(true);
    }
    setSearchInput((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowHealthManagerList(false);
    setMembers([{ Doc: null, Role: "" }]);
    setSearchInput({});
  };

  const toggleDropdown = (id) => {
    setShowDropDown((prev) => (prev === id ? null : id));
  };

  const handleStatusSelect = (id, control) => {
    const body = {
      AccessControl: control,
    };
    dispatch(updateMedicalCommittee({ id, control }));
    dispatch(
      updateMedicalCommitteeMember(token, selectedMember?.MCID, body, patient)
    );
    setSelectedMember(null);
    setShowDropDown(null);
  };

  const closeDeleteModal = () => {
    setSelectedMember(null);
    setShowDeleteModal(false);
  };

  const handleDeleteMember = () => {
    dispatch(
      deleteMedicalCommitteeMember(token, selectedMember?.MCID, patient)
    );
    closeDeleteModal();
  };

  const handleHealthManagerSelect = (manager, index) => {
    const newMembers = [...members];
    newMembers[index].Doc = manager;
    setMembers(newMembers);
    setSearchInput((prevState) => ({
      ...prevState,
      [index]: manager.Name,
    }));
    setShowHealthManagerList(false);
    validateField(index, "Doc", manager);
  };

  const handleRoleChange = (index, field, value) => {
    const newMembers = [...members];
    newMembers[index][field] = value;
    setMembers(newMembers);
    validateField(index, field, value);
  };

  const validateField = (index, field, value) => {
    const newErrors = [...errors];
    newErrors[index] = newErrors[index] || {};
    newErrors[index][field] = !value;
    setErrors(newErrors);
  };

  const handleAddCommitteeMembers = () => {
    const newErrors = members.map((member) => ({
      Doc: !member.Doc,
      Role: !member.Role,
    }));
    setErrors(newErrors);

    const hasErrors = newErrors.some((error) => error.Doc || error.Role);
    if (hasErrors) {
      toast.error("Please fill in all required fields.");
      return;
    }

    const formattedMembers = members?.map((member) => ({
      DID: member.Doc.DID,
      AHUID: patient?.AHUID,
      Role: member.Role,
    }));
    dispatch(addMedicalCommitteeMember(token, formattedMembers, patient));
    closeDeleteModal();
  };

  return (
    <div className="px-6">
      <ModalComponent
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteIcon} alt="" />
          </div>
          <div className="text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Remove '{selectedMember?.Name}'?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Once removed, they will no longer be a part of this medical
            committee.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={closeDeleteModal}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleDeleteMember}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Remove
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showModal}
        handleClose={handleCloseModal}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-row items-center justify-start space-x-4">
          <div className="w-10 h-10 bg-sky-100 rounded-3xl flex justify-center items-center">
            <img src={medicalCommitteeSelectedIcon} alt="" />
          </div>
          <div className="text-slate-800 text-xl font-medium font-poppins leading-normal">
            Add HCP to Medical Committee for{" "}
            {patient.Name || patient.FirstName + " " + patient.LastName}'s
          </div>
        </div>
        <div className="mt-6 shadow rounded-md">
          <div className="grid grid-cols-2 gap-4 bg-gray-50 border-b border-gray-200 p-4">
            <div className="text-slate-800 text-sm font-medium font-poppins leading-none">
              Member Name
            </div>
            <div className="text-slate-800 text-sm font-medium font-poppins leading-none">
              Assign Role
            </div>
          </div>

          {members.map((member, index) => (
            <div key={index} className="p-4 grid grid-cols-2 gap-4">
              <div className="relative">
                <label>
                  <input
                    type="text"
                    value={searchInput[index] || ""}
                    onChange={(e) =>
                      handleSearchInputChange(index, e.target.value)
                    }
                    placeholder="Search"
                    className={`w-full ${
                      errors[index]?.Doc ? "border-red-500" : ""
                    } placeholder:text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    autoComplete="off"
                  />
                </label>
                {showHealthManagerList && currentIndex === index && (
                  <div className="absolute left-0 mt-1 w-full bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10 max-h-[35vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    {filteredHealthManagers?.length > 0 ? (
                      filteredHealthManagers?.slice(0, 4).map((manager) => (
                        <button
                          key={manager.Doc}
                          onClick={() =>
                            handleHealthManagerSelect(manager, index)
                          }
                          className={`w-full px-4 py-2  flex items-center justify-between hover:bg-gray-100`}
                        >
                          <div className="flex items-center space-x-2">
                            <img
                              src={manager.Image}
                              alt={manager.Name}
                              className="w-6 h-6 rounded-full object-cover"
                            />
                            <p className="text-zinc-900 text-base font-normal font-poppins leading-normal truncate">
                              {manager.Name}
                            </p>
                          </div>

                          {member?.Doc?.Name === manager.Name && (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 14.5C5 14.5 6.5 14.5 8.5 18C8.5 18 14.0588 8.83333 19 7"
                                stroke="#3CA5DC"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </button>
                      ))
                    ) : (
                      <div className="p-2 flex flex-col space-y-4">
                        <div className="flex items-center space-x-2">
                          <img src={noMatchFound} alt="" className="w-14 h-9" />
                          <div className="text-center text-black text-sm font-medium font-poppins leading-normal">
                            No Matches Found !
                          </div>
                        </div>
                        <div className="text-center text-zinc-500 text-xs font-normal font-poppins leading-normal">
                          It looks like the person you're searching for isn't in
                          the list. Add them in the HCP clinic network.
                        </div>
                        <button
                          onClick={() => {
                            handleCloseModal();
                            setShowAddNewModal(true);
                          }}
                          className="text-sky-700 text-base font-semibold font-poppins leading-tight"
                        >
                          Add new
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div>
                <Dropdown
                  placeholder="Select"
                  error={errors[index]?.Role}
                  name="Role"
                  options={ROLEOPTIONS}
                  onSelect={(name, option) =>
                    handleRoleChange(index, "Role", option)
                  }
                  value={member.Role}
                  labelKey="title"
                  valueKey="title"
                />
              </div>
            </div>
          ))}
        </div>

        {members.length < 3 && (
          <button
            onClick={handleAddMore}
            className="mt-6 flex items-center justify-center text-sky-400 text-base font-semibold font-poppins leading-tight"
          >
            <FaPlus className="mr-2" />
            Add more
          </button>
        )}
        <div className="flex flex-row items-center justify-center mt-6 space-x-4">
          <button
            onClick={handleCloseModal}
            className="w-full h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
          >
            <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
              Cancel
            </div>
          </button>
          <button
            onClick={handleAddCommitteeMembers}
            className="w-full h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
          >
            <div className="text-white text-base font-semibold font-poppins leading-normal">
              Add to Committee
            </div>
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showAddNewModal}
        handleClose={() => setShowAddNewModal(false)}
        outSideModalPress={false}
        showCloseButton={true}
      >
        <div className="w-[25vw]">
          <h1 className="text-slate-800 text-xl font-medium font-['Poppins'] leading-normal">
            Select who to add to the network
          </h1>
          {ROUTEDATA.map((route) => (
            <div key={route.id} className="mt-4">
              <button
                onClick={() => {
                  setShowAddNewModal(false);
                  navigate(route?.path);
                }}
                className="w-full p-4 rounded-xl shadow border border-gray-200 flex items-center justify-between space-x-4"
              >
                <div className="flex items-center space-x-2">
                  <img
                    src={route?.icon}
                    alt=""
                    className="w-6 h-6 object-contain"
                  />
                  <p className="text-neutral-600 text-base font-medium font-poppins leading-tight">
                    {route?.title}
                  </p>
                </div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.00005 18C9.00005 18 15 13.5811 15 12C15 10.4188 9 6 9 6"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      </ModalComponent>
      <div>
        <h1 className="text-zinc-900 text-base font-medium font-poppins leading-7">
          Form a Medical Committee to collaborate on a case.
        </h1>
        <h2
          className="
      text-zinc-500 text-sm font-normal font-poppins leading-relaxed"
        >
          Include healthcare professionals (HCPs) who should have access to the
          patient's records. These HCPs must be part of your clinic's network.
        </h2>
      </div>

      {medicalCommitteeMembers?.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full p-20">
          <img
            src={noPatientFound}
            alt=""
            className="w-1/2 h-72 object-contain"
          />
          <div className="text-black text-xl font-bold font-Inter">
            {hasAccess(accessRoles, [ACCESS_ROLES.ADMIN])
              ? "Add"
              : "Ask your Admin to add"}{" "}
            HCP to view them on this screen
          </div>
          {hasAccess(accessRoles, [ACCESS_ROLES.ADMIN]) && (
            <button
              className="w-1/2 mx-auto bg-sky-400 text-center text-stone-50 text-sm font-semibold font-poppins py-3 rounded-lg mt-4 hover:opacity-80"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add New Member
            </button>
          )}
        </div>
      ) : (
        <>
          {/* Search and Button  */}
          <div className="flex flex-row items-center justify-between mt-5">
            <label className="w-2/3 flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
              <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
              <input
                type="text"
                placeholder="Type a Name Here"
                value={searchTerm}
                onChange={handleSearchChange}
                className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
              />
            </label>

            {hasAccess(accessRoles, [ACCESS_ROLES.ADMIN]) && (
              <button
                onClick={() => {
                  setShowModal(true);
                }}
                className="px-3 py-2 bg-sky-400 rounded shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
              >
                <FaPlus className="text-white text-sm" />
                <div className="text-white text-sm font-normal font-poppins">
                  Add New Member
                </div>
              </button>
            )}
          </div>
          <div className="w-full mx-auto mt-4 shadow rounded-md">
            <table className="min-w-full">
              <thead>
                <tr className="text-left bg-gray-50 border-b border-gray-200">
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Name
                  </th>
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Role
                  </th>
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Contact Details
                  </th>
                  {hasAccess(accessRoles, [ACCESS_ROLES.ADMIN]) && (
                    <>
                      <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                        Access Control
                      </th>
                      <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                        Actions
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {currentUsers?.map((user) => (
                  <tr
                    key={user.id}
                    className="border-b border-gray-200 hover:bg-sky-50"
                  >
                    <td className="p-4">
                      <div
                        onClick={() =>
                          navigate("/patientProfile", { state: user })
                        }
                        className="flex items-center cursor-pointer"
                      >
                        <img
                          src={user.Image}
                          alt={user.Name}
                          className="w-10 h-10 object-cover rounded-full mr-3"
                        />
                        <div>
                          <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                            {user?.Name}
                          </p>
                          <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                            {user?.Qualification}, Exp {user?.Experience} Years
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                      {user?.Role}
                    </td>
                    <td className="p-4">
                      <div className="flex flex-row items-center space-x-2">
                        <Tooltip
                          message={user?.Phone || "No phone number available"}
                          showCopyButton={true}
                        >
                          <button className="p-1 rounded-full group group-hover:bg-sky-100">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                            >
                              <path
                                d="M9.15826 5.71223L8.7556 4.80625C8.49232 4.21388 8.36068 3.91768 8.16381 3.69101C7.91708 3.40694 7.59547 3.19794 7.23568 3.08785C6.94859 3 6.62446 3 5.97621 3C5.02791 3 4.55376 3 4.15573 3.18229C3.68687 3.39702 3.26344 3.86328 3.09473 4.3506C2.95151 4.76429 2.99254 5.18943 3.07458 6.0397C3.94791 15.0902 8.90982 20.0521 17.9603 20.9254C18.8106 21.0075 19.2358 21.0485 19.6494 20.9053C20.1368 20.7366 20.603 20.3131 20.8178 19.8443C21 19.4462 21 18.9721 21 18.0238C21 17.3755 21 17.0514 20.9122 16.7643C20.8021 16.4045 20.5931 16.0829 20.309 15.8362C20.0824 15.6393 19.7862 15.5077 19.1938 15.2444L18.2878 14.8417C17.6463 14.5566 17.3255 14.4141 16.9996 14.3831C16.6876 14.3534 16.3731 14.3972 16.0811 14.5109C15.776 14.6297 15.5064 14.8544 14.967 15.3038C14.4302 15.7512 14.1618 15.9749 13.8338 16.0947C13.543 16.2009 13.1586 16.2403 12.8524 16.1951C12.5069 16.1442 12.2424 16.0029 11.7133 15.7201C10.0673 14.8405 9.15953 13.9328 8.27987 12.2867C7.99714 11.7577 7.85578 11.4931 7.80487 11.1477C7.75974 10.8414 7.79908 10.457 7.9053 10.1663C8.02512 9.83828 8.24881 9.56986 8.69619 9.033C9.14562 8.49368 9.37034 8.22402 9.48915 7.91891C9.60285 7.62694 9.64662 7.3124 9.61695 7.00048C9.58594 6.67452 9.44338 6.35376 9.15826 5.71223Z"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          </button>
                        </Tooltip>

                        <Tooltip
                          message={user?.Email || "No email available"}
                          showCopyButton={true}
                        >
                          <button className="p-1 rounded-full group group-hover:bg-sky-100">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                            >
                              <path
                                d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </Tooltip>
                      </div>
                    </td>
                    {hasAccess(accessRoles, [ACCESS_ROLES.ADMIN]) && (
                      <>
                        <td className="p-4">
                          <div className="relative">
                            <div
                              onClick={() => {
                                toggleDropdown(user.MCID);
                                setSelectedMember(user);
                              }}
                              className="w-2/3 px-3 py-2 bg-white border border-sky-400 rounded-full text-sm text-slate-700 font-normal flex items-center justify-between cursor-pointer"
                            >
                              <div className="flex items-center space-x-1">
                                <div className="text-center text-slate-700 text-sm font-normal font-poppins leading-tight">
                                  {STATUS.find(
                                    (status) =>
                                      status.value === user.AccessControl
                                  )?.label || "None"}
                                </div>
                              </div>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15 7.50004C15 7.50004 11.3176 12.5 10 12.5C8.68233 12.5 5 7.5 5 7.5"
                                  stroke="black"
                                  strokeWidth="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            {showDropDown === user.MCID && (
                              <div className="absolute w-2/3 mt-1 bg-white border rounded-lg p-2 space-y-1 shadow-lg z-10">
                                {STATUS.map((x) => (
                                  <button
                                    key={x.value}
                                    onClick={() =>
                                      handleStatusSelect(user.MCID, x.value)
                                    }
                                    className={`w-full flex flex-row items-center justify-between px-4 py-2 text-sm rounded-lg ${
                                      user.AccessControl === x.value
                                        ? "bg-slate-50 text-sky-400 hover:bg-sky-200 hover:text-sky-600"
                                        : "text-black hover:bg-gray-100 hover:text-gray-800"
                                    }`}
                                  >
                                    <div>{x.label}</div>
                                    {user.AccessControl === x.value && (
                                      <svg
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M17.3353 5.5L8.16862 14.6667L4.00195 10.5"
                                          stroke="#3CA5DC"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                ))}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="p-4">
                          <div className="flex flex-row items-center space-x-2">
                            {/* <Tooltip message="View Profile">
                          <button className="p-1 rounded-full group group-hover:bg-sky-100">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                            >
                              <path
                                d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                                stroke="currentColor"
                                stroke-width="1.5"
                              />
                              <path
                                d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                                stroke="currentColor"
                                stroke-width="1.5"
                              />
                            </svg>
                          </button>
                        </Tooltip> */}

                            <Tooltip message="Delete">
                              <button
                                onClick={() => {
                                  setSelectedMember(user);
                                  setShowDeleteModal(true);
                                }}
                                className="p-1 rounded-full group group-hover:bg-sky-100"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                                >
                                  <path
                                    d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M9.30078 16.5V10.5"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M14.3008 16.5V10.5"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            totalItems={filteredMembers.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
}

export default MedicalCommittee;

const ROLEOPTIONS = [
  {
    id: 1,
    title: "Doctor",
  },
  {
    id: 2,
    title: "Health Manager",
  },
  {
    id: 3,
    title: "Treating Physician",
  },
  {
    id: 4,
    title: "Nurse",
  },
  {
    id: 5,
    title: "Consulting Physician",
  },
  {
    id: 6,
    title: "Medical Assistant",
  },
];

const STATUS = [
  { label: "View Only", value: "VIEW" },
  { label: "Edit-Access", value: "EDIT" },
];

const ROUTEDATA = [
  {
    id: 1,
    title: "Doctor",
    icon: doctorIcon,
    path: "/addNewDoctor",
  },
  {
    id: 2,
    title: "Nurse",
    icon: nurseIcon,
    path: "/addNewNurse",
  },
  {
    id: 3,
    title: "Health Manager",
    icon: healthManagerIcon,
    path: "/addNewHealthManager",
  },
];
