import React, { useEffect, useState } from "react";
import arrowUp from "../assets/Images/MedicalConcierge/arrowUp.svg";
import arrowDown from "../assets/Images/MedicalConcierge/arrowDown.svg";
import viewIcon from "../assets/Images/MedicalConcierge/view.svg";
import editIcon from "../assets/Images/MedicalConcierge/edit.svg";
import deleteIcon from "../assets/Images/MedicalConcierge/delete.svg";
import settingSelected from "../assets/Images/Sidebar/settingSelected.svg";
import addHealthManagerIcon from "../assets/Images/MedicalConcierge/addHealthManagerIcon.svg";
import appointment from "../assets/Images/MedicalConcierge/appointment.svg";
import call from "../assets/Images/MedicalConcierge/call.svg";
import moment from "moment";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import ConciergeMembers from "../components/ConciergeMembers";
import HealthManagers from "../components/HealthManagers";
import ModalComponent from "../components/Modal";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { assignHealthManagerBulk } from "../services/operations/medicalConciergeAPI";

function MedicalConcierge() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const {
    conciergeMembers,
    healthManagers,
    loading: medicalConciergeLoading,
  } = useSelector((state) => state.medicalConcierge);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showAddBulkManagers, setShowAddBulkManagers] = useState(false);
  const [selectedHealthManagers, setSelectedHealthManagers] = useState({});
  const [showHealthManagerList, setShowHealthManagerList] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [searchInputs, setSearchInputs] = useState({});
  const currentMonth = moment().format("MMMM");
  const currentYear = moment().format("YYYY");

  const usersJoinedThisMonth = conciergeMembers.filter((user) => {
    const joinMonth = moment(
      user.MedicalConciergeJoiningDate,
      "YYYY-MM-DD"
    ).format("MMMM");
    const joinYear = moment(
      user.MedicalConciergeJoiningDate,
      "YYYY-MM-DD"
    ).format("YYYY");
    return joinMonth === currentMonth && joinYear === currentYear;
  });

  const percentageJoinedThisMonth = (
    (usersJoinedThisMonth.length / conciergeMembers.length) *
    100
  ).toFixed(2);

  const renderTabContent = (tab) => {
    switch (tab) {
      case 0:
        return (
          <ConciergeMembers
            users={conciergeMembers}
            managers={healthManagers}
            loading={medicalConciergeLoading}
          />
        );
      case 1:
        return (
          <HealthManagers
            managers={healthManagers}
            loading={medicalConciergeLoading}
          />
        );
      case 2:
        return (
          <div className="flex flex-1 flex-col items-center justify-center h-96">
            <div className="text-sky-400 text-lg font-semibold font-poppins leading-tight">
              Coming Soon
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const closeAddBulkManagersModal = () => {
    setShowAddBulkManagers(false);
    setShowHealthManagerList(false);
  };

  // Function to handle search input change for each user
  const handleSearchInputChange = (userId, value) => {
    setSearchInputs((prevState) => ({
      ...prevState,
      [userId]: value,
    }));
  };

  // Function to handle health manager selection
  const handleHealthManagerSelect = (userId, manager) => {
    setSelectedHealthManagers((prevState) => ({
      ...prevState,
      [userId]: manager,
    }));
    setSearchInputs((prevState) => ({
      ...prevState,
      [userId]: manager.name,
    }));
    setShowHealthManagerList(false);
    setSearchInputs({});
  };

  // Function to filter health managers based on search input for each user
  const filteredHealthManagers = (userId) => {
    return healthManagers?.filter((manager) =>
      manager?.Name?.toLowerCase().includes(
        searchInputs[userId]?.toLowerCase() || ""
      )
    );
  };

  const handleAssignHealthManager = () => {
    const assignments = Object.keys(selectedHealthManagers).map((AHUID) => ({
      AHUID,
      DID: String(selectedHealthManagers[AHUID].DID),
    }));

    const data = { assignments };

    console.log(data);

    dispatch(assignHealthManagerBulk(token, data));
    setShowAddBulkManagers(false);
    // setSelectedHealthManagers(null);
  };

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <ModalComponent
        show={showAddBulkManagers}
        handleClose={closeAddBulkManagersModal}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-row items-center justify-start space-x-4">
          <div className="w-10 h-10 bg-sky-100 rounded-3xl flex justify-center items-center">
            <img src={addHealthManagerIcon} alt="" />
          </div>
          <div className="text-slate-800 text-xl font-medium font-poppins leading-normal">
            Assign personal health managers
          </div>
        </div>
        <div className="container w-[40vw] mx-auto mt-6 shadow rounded-md">
          <table className="min-w-full">
            <thead>
              <tr className="text-left bg-gray-50 border-b border-gray-200">
                <th className="p-4 text-slate-800 text-sm font-medium font-poppins leading-none">
                  Name
                </th>
                <th className="p-4 text-slate-800 text-sm font-medium font-poppins leading-none">
                  Joining Date
                </th>
                <th className="p-4 text-slate-800 text-sm font-medium font-poppins leading-none">
                  Health Manager
                </th>
              </tr>
            </thead>
            <tbody>
              {conciergeMembers
                .filter((user) => user.HealthManager === null)
                .map((user) => (
                  <tr key={user?.AHUID} className="border-b border-gray-200">
                    <td className="p-4">
                      <div className="flex items-center space-x-3">
                        <div className="bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5">
                          <img
                            src={user?.Image}
                            alt="PatientPic"
                            className="w-10 h-10 rounded-full "
                          />
                        </div>
                        <div>
                          <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                            {user.FirstName + " " + user.LastName}
                          </p>
                          <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                            {user.Gender},{" "}
                            {new Date().getFullYear() -
                              new Date(user.DOB).getFullYear()}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                      {moment(user?.MedicalConciergeJoiningDate).format(
                        "DD.MM.YY"
                      )}
                    </td>
                    <td className="p-4 relative">
                      <label>
                        <input
                          type="text"
                          value={selectedHealthManagers[user?.AHUID]?.Name}
                          onChange={(e) =>
                            handleSearchInputChange(user?.AHUID, e.target.value)
                          }
                          onFocus={() => {
                            setSelectedPatient(user);
                            setShowHealthManagerList(true);
                          }}
                          placeholder="Select"
                          className="w-full h-10 p-4 bg-gray-100 rounded-lg border flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-sky-400"
                          autoComplete="off"
                        />
                        <div className="absolute inset-y-0 right-3 pr-4 flex items-center text-gray-400">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                              stroke="black"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </label>
                      {showHealthManagerList &&
                        selectedPatient.AHUID === user?.AHUID && (
                          <div className="absolute left-4 mt-1 w-64 bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10">
                            {filteredHealthManagers(user?.AHUID)?.length > 0 ? (
                              filteredHealthManagers(user?.AHUID)
                                .slice(0, 4)
                                .map((manager) => (
                                  <button
                                    key={manager.DID}
                                    onClick={() =>
                                      handleHealthManagerSelect(
                                        user?.AHUID,
                                        manager
                                      )
                                    }
                                    className={`flex items-center justify-between w-full text-left px-4 py-2 hover:bg-gray-100 ${
                                      selectedHealthManagers[user?.AHUID]
                                        ?.DID === manager.DID
                                        ? "text-sky-400"
                                        : "text-zinc-900 "
                                    } text-sm font-normal font-poppins leading-tight`}
                                  >
                                    {manager?.Name}
                                    {selectedHealthManagers[user?.AHUID]
                                      ?.DID === manager.DID && (
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M5 14.5C5 14.5 6.5 14.5 8.5 18C8.5 18 14.0588 8.83333 19 7"
                                          stroke="#3CA5DC"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                ))
                            ) : (
                              <div className="text-center text-zinc-900 text-sm font-normal font-poppins leading-tight py-2">
                                No manager found
                              </div>
                            )}
                          </div>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-row items-center justify-end mt-6 space-x-4">
          <button
            onClick={closeAddBulkManagersModal}
            className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
          >
            <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
              Cancel
            </div>
          </button>
          <button
            onClick={() => handleAssignHealthManager()}
            className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
          >
            <div className="text-white text-base font-semibold font-poppins leading-normal">
              Confirm
            </div>
          </button>
        </div>
      </ModalComponent>

      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Medical Concierge
        </div>
        <button
          onClick={() => {
            navigate("medicalConciergeSettings");
          }}
          className="flex flex-row items-center space-x-1 active:scale-95"
        >
          <img src={settingSelected} alt="" />
          <div className="text-sky-400 text-sm font-semibold font-poppins leading-none">
            Medical Concierge Settings
          </div>
        </button>
      </div>
      <Breadcrumb />

      {/* Cards */}
      <div className="flex flex-row items-stretch space-x-4 mt-6">
        {/* Card 1 */}
        <div className="w-80 bg-white rounded-2xl shadow border border-gray-200 p-4">
          <div className="text-neutral-600 text-sm font-medium font-poppins leading-tight">
            Total members
          </div>

          {/* Stats */}
          <div className="flex flex-row items-center justify-between mt-2">
            <div className="text-gray-900 text-4xl font-semibold font-poppins leading-10">
              {conciergeMembers?.length}
            </div>
            <div className="flex flex-row items-center">
              <img
                src={arrowUp}
                alt=""
                className="w-5 h-5 object-contain mr-1 animate-bounce"
              />
              <div className="text-center text-emerald-500 text-sm font-medium font-poppins leading-tight mr-2">
                {percentageJoinedThisMonth}%
              </div>
              <div className="text-neutral-600 text-sm font-normal font-poppins leading-tight">
                vs last month
              </div>
            </div>
          </div>

          <button className="px-4 py-2 bg-white rounded-lg border border-sky-400 mt-4 active:scale-95">
            <div className="text-sky-400 text-sm font-semibold font-poppins leading-tight">
              Send Joining Notification
            </div>
          </button>

          <div className="text-slate-800 text-sm font-normal font-poppins leading-tight mt-1">
            Last notification sent on: {moment().format("DD.MM.YY")}
          </div>
        </div>
        {/* Card 2 */}
        <div className=" bg-white rounded-2xl shadow border border-gray-200 p-4">
          <div className="text-neutral-600 text-sm font-medium font-poppins leading-tight">
            New Members
          </div>

          {/* Stats */}
          <div className="flex flex-row items-center justify-between space-x-8 mt-2">
            <div className="text-gray-900 text-4xl font-semibold font-poppins leading-10">
              {usersJoinedThisMonth.length}
            </div>
            <div className="flex flex-col space-y-2">
              <div
                className={`px-2 py-1 ${
                  conciergeMembers.filter((user) => !user.HealthManager)
                    .length > 0
                    ? "bg-orange-50"
                    : "bg-green-50"
                }  rounded-2xl flex flex-row items-center justify-center space-x-1`}
              >
                <div
                  className={` ${
                    conciergeMembers.filter((user) => !user.HealthManager)
                      .length > 0
                      ? "bg-orange-300"
                      : "bg-green-300"
                  } w-2.5 h-2.5 rounded-full animate-pulse`}
                />
                <div
                  className={`${
                    conciergeMembers.filter((user) => !user.HealthManager)
                      .length > 0
                      ? "text-orange-800"
                      : "text-green-800"
                  } text-sm font-medium font-poppins leading-tight`}
                >
                  Unassigned Health Managers :{" "}
                  {
                    conciergeMembers.filter((user) => !user.HealthManager)
                      .length
                  }
                </div>
              </div>
              <div className="text-neutral-700 text-xs font-medium font-poppins leading-tight">
                Follow up Needed: (
                {
                  conciergeMembers.filter((user) => user.Request !== null)
                    .length
                }
                )
              </div>
            </div>
          </div>

          {conciergeMembers.filter((user) => !user.HealthManager).length >
            0 && (
            <>
              <div className="border border-b-1 border-gray-100 my-4" />
              <button
                onClick={() => setShowAddBulkManagers(true)}
                className="px-4 py-2 bg-white rounded-lg border border-sky-400 active:scale-95"
              >
                <div className="text-sky-400 text-sm font-semibold font-poppins leading-tight">
                  Assign Health Managers
                </div>
              </button>
            </>
          )}
        </div>
      </div>

      {/* Tabs & Add New Button */}
      <div className="flex flex-row items-center justify-between mt-6">
        {/* Tabs  */}
        <div className=" flex flex-row items-center space-x-1">
          <button
            onClick={() => setSelectedTab(0)}
            className={`w-48 ${
              selectedTab === 0
                ? "bg-sky-50 text-sky-400 font-semibold  rounded-tl-2xl rounded-tr-2xl"
                : "text-neutral-600 font-normal"
            }  flex flex-col justify-center items-center`}
          >
            <div className={`text-sm font-poppins leading-tight m-3`}>
              All Members
            </div>
            <div
              className={`w-full h-0.5 ${
                selectedTab === 0 ? "bg-sky-400" : "bg-gray-200"
              } rounded-xl`}
            />
          </button>
          <button
            onClick={() => setSelectedTab(1)}
            className={`w-48 ${
              selectedTab === 1
                ? "bg-sky-50 text-sky-400 font-semibold  rounded-tl-2xl rounded-tr-2xl"
                : "text-neutral-600 font-normal"
            }  flex flex-col justify-center items-center`}
          >
            <div className={`text-sm font-poppins leading-tight m-3`}>
              Health Managers
            </div>
            <div
              className={`w-full h-0.5 ${
                selectedTab === 1 ? "bg-sky-400" : "bg-gray-200"
              } rounded-xl`}
            />
          </button>
          <button
            onClick={() => setSelectedTab(2)}
            className={`w-48 ${
              selectedTab === 2
                ? "bg-sky-50 text-sky-400 font-semibold  rounded-tl-2xl rounded-tr-2xl"
                : "text-neutral-600 font-normal"
            }  flex flex-col justify-center items-center`}
          >
            <div className={`text-sm font-poppins leading-tight m-3`}>
              Chat Messanger
            </div>
            <div
              className={`w-full h-0.5 ${
                selectedTab === 2 ? "bg-sky-400" : "bg-gray-200"
              } rounded-xl`}
            />
          </button>
        </div>

        {/* Add New Member*/}
        {selectedTab !== 2 && (
          <button
            onClick={() => {
              if (selectedTab === 0) navigate("addNewConciergePatient");
              else {
                navigate("addNewHealthManager");
              }
            }}
            className="px-3 py-2 bg-sky-400 rounded shadow justify-center items-center gap-2.5 flex cursor-pointer active:scale-95 duration-200"
          >
            <FaPlus className="text-white text-sm" />
            <div className="text-white text-sm font-normal font-poppins">
              {selectedTab === 0 ? "Add New Member" : "Add Health Manager"}
            </div>
          </button>
        )}
      </div>

      {renderTabContent(selectedTab)}
    </div>
  );
}

export default MedicalConcierge;
