import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft } from "react-icons/fa6";
import Vertical_switch_light from "../../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import previewIcon from "../../assets/Images/Invoicing/previewIcon.png";
import generateInvoiceIcon from "../../assets/Images/Invoicing/generateInvoiceIcon.png";
import reminderIcon from "../../assets/Images/Invoicing/reminderIcon.png";
import showInvoice from "../../assets/Images/Invoicing/showInvoice.png";
import createInvoice from "../../assets/Images/Invoicing/createInvoice.png";
import { useDispatch, useSelector } from "react-redux";
import {
    getInvoices,
    getPatientsCount,
    getSpecificInvoice,
    invoice,
} from "../../services/operations/masterDataAPI";
import moment from "moment";
import ModalComponent from "../../components/Modal";
import AHlogo from "../../assets/Images/logo.png";
import Breadcrumb from "../../components/Breadcrumb";
import qrCodeImg from "../../assets/Images/Invoicing/qrCodeImg.png";
import { IoIosSearch } from "react-icons/io";
import Tooltip from "../../components/Tooltip";
import Pagination from "../../components/Pagination";
import { getBucketURL } from "../../utils/regex";
import { FaBell } from "react-icons/fa";

function NotificationDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const { customNotificationList } = useSelector((state) => state.masterData);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('ALL');
    const [currentPage, setCurrentPage] = useState(1);
    const [patientCountData, setPatientCountData] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const itemsPerPage = 8;

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = () => {
        let results = customNotificationList;

        // Filter by search query (checking category name or date/time)
        if (searchQuery) {
            results = results.filter((notification) => {
                const categoryName = notification?.AnnouncementCategory?.category_name?.toLowerCase();
                const formattedDateTime = `${moment(notification?.CustomDate).format("DD/MM/YYYY")} ${moment(notification?.CustomTime, "HH:mm:ss").format("HH:mm A")}`;
                return (
                    categoryName?.includes(searchQuery.toLowerCase()) ||
                    formattedDateTime?.includes(searchQuery.toLowerCase())
                );
            });
        }

        // Filter by selected NotifyUsers status
        if (selectedFilter && selectedFilter !== 'ALL') {
            results = results.filter((notification) =>
                selectedFilter === 'SENT'
                    ? notification?.NotifyUsers === 'Send Now'
                    : selectedFilter === 'DRAFT'
                        ? notification?.NotifyUsers === 'Draft'
                        : selectedFilter === 'SCHEDULED'
                            ? notification?.NotifyUsers === 'Custom'
                            : true
            );
        }

        setFilteredNotifications(results);
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        const filterValue = e.target.innerText.toUpperCase();
        setSelectedFilter(filterValue);
    };

    useEffect(() => {
        handleSearch();
        setCurrentPage(1);
    }, [searchQuery, selectedFilter, customNotificationList]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCustomNotificationList = filteredNotifications?.slice(
        indexOfFirstItem,
        indexOfLastItem
    );

    useEffect(() => {
        const fetchPatientsCount = async () => {
            if (token) {
                try {
                    const res = await dispatch(getPatientsCount(token));
                    setPatientCountData(res);
                } catch (error) {
                    console.error("Failed to fetch patient count", error);
                }
            }
        };

        fetchPatientsCount();
        setIsLoading(false);
    }, [token, dispatch]);

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="spinner"></div>
            </div>
        );
    }

    if (customNotificationList?.length === 0) {
        return (
            <div className="bg-white min-h-screen py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
                <div className="flex flex-col justify-center items-start gap-2">
                    <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                        Notification Management
                    </h1>
                    <Breadcrumb />
                    <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins mt-4">
                        No custom notification found.
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            {/* HEADER */}
            <div className="flex flex-row justify-between items-center w-full">
                {/* TITLE */}
                <div className="flex flex-row justify-between items-center w-full">
                    <div className="flex flex-col justify-center items-start gap-2">
                        <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                            Notification Management
                        </h1>
                        <Breadcrumb />
                    </div>
                    <button
                        onClick={() => { navigate("/serviceManagement/notificationManagement/customNotification") }}
                        className={`w-44 flex flex-row justify-center items-center rounded-[4px] bg-[#3CA5DC] p-2 gap-1 hover:scale-95`}>
                        <FaBell className="w-6 h-6 text-white" />
                        <h1 className={`text-[#FFFFFF] font-medium text-[10px] font-poppins leading-[15px]`}>
                            Send Custom Notification
                        </h1>
                    </button>
                </div>
            </div>

            {/* Content */}
            <div className="flex flex-col justify-center items-center w-full mt-6">
                {/* Search and Filter */}
                <div className="flex flex-row justify-between items-center w-full">
                    {/* Search */}
                    <label className="flex flex-row items-center justify-start bg-gray-100 rounded-full py-2 px-4 gap-2 w-[32rem]">
                        <IoIosSearch className="text-[#acacac] w-5 h-5" />
                        <input
                            type="text"
                            placeholder="Search by Category, date and time"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            className="bg-gray-100 text-sm text-[#acacac] font-normal font-poppins focus:outline-none focus:border-transparent w-full"
                        />
                    </label>

                    {/* Filter */}
                    <div className="flex flex-row justify-center items-center">
                        {['All', 'Sent', 'Draft', 'Scheduled'].map((filter, index, array) => (
                            <button
                                key={filter}
                                className={`py-2 px-4 ${index === 0 ? 'rounded-l-lg' : ''} ${index === array.length - 1 ? 'rounded-r-lg' : ''} ${selectedFilter === filter.toUpperCase()
                                    ? 'bg-[#E5F4FE] border-[#AFD8F2]'
                                    : 'bg-white border-[#D4D4D4]'
                                    } ${selectedFilter === filter.toUpperCase() ? 'border' : 'border'}`}
                                onClick={handleFilterChange}
                            >
                                <h1 className={`${selectedFilter === filter.toUpperCase() ? "text-[#007AAE]" : "text-[#545454]"} text-sm font-medium font-poppins`}>
                                    {filter}
                                </h1>
                            </button>
                        ))}
                    </div>
                </div>

                {/* Table Header */}
                <div
                    className="mt-6 grid grid-cols-6 justify-between items-center w-full bg-[#E5F2FF] py-3 px-6 border-b-2 border-[#EAECF0] rounded-sm gap-1"
                >
                    <div className="flex flex-row justify-start items-center gap-1">
                        <h1 className="text-[#263238] text-xs font-medium font-poppins leading-[18px]">
                            Category
                        </h1>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-1">
                        <h1 className="text-[#263238] text-xs font-medium font-poppins leading-[18px]">
                            Date & Time
                        </h1>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-1">
                        <h1 className="text-[#263238] text-xs font-medium font-poppins leading-[18px]">
                            Status
                        </h1>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-1">
                        <h1 className="text-[#263238] text-xs font-medium font-poppins leading-[18px]">
                            Total Audience
                        </h1>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-1">
                        <h1 className="text-[#263238] text-xs font-medium font-poppins leading-[18px]">
                            Action
                        </h1>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-1">
                        <h1 className="text-[#263238] text-xs font-medium font-poppins leading-[18px]">
                            Message
                        </h1>
                    </div>
                </div>

                {/* Table Content */}
                {currentCustomNotificationList?.length === 0 && (
                    <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins mt-4 w-full text-center">
                        No Custom Notification found
                    </h1>
                )}
                {console.log("currentCustomNotificationList", currentCustomNotificationList)}
                {currentCustomNotificationList?.slice(0, itemsPerPage).map((notification) => (
                    <React.Fragment key={notification?.ID}>
                        <div className="grid grid-cols-6 justify-between items-center w-full bg-[#F9F9F9] py-2 px-7 border-b-2 border-[#EAECF0] rounded-sm gap-1 mt-3">
                            <div className="flex flex-row justify-start items-center gap-1">
                                <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px]">
                                    {notification?.AnnouncementCategory?.category_name}
                                </h1>
                            </div>
                            <div className="flex flex-row justify-start items-center gap-3">
                                <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px]">
                                    {`${moment(notification?.CustomDate).format("DD/MM/YYYY")}, ${moment(`${notification?.CustomDate} ${notification?.CustomTime}`, "YYYY-MM-DD HH:mm:ss").format("HH:mm A")}`}
                                </h1>
                            </div>
                            {notification?.NotifyUsers === "Send Now" && (
                                <div className="flex flex-row justify-start items-center">
                                    <div className="flex flex-row justify-center items-center bg-[#E5F2FF] rounded-2xl px-5 py-2 w-20">
                                        <h1 className="text-[#007AAE] text-xs font-normal font-poppins leading-[18px]">
                                            Sent
                                        </h1>
                                    </div>
                                </div>
                            )}
                            {notification?.NotifyUsers === "Custom" && (
                                <div className="flex flex-row justify-start items-center">
                                    <div className="flex flex-row justify-center items-center bg-[#D4FEE2] rounded-2xl px-5 py-2 w-24">
                                        <h1 className="text-[#3E8920] text-xs font-normal font-poppins leading-[18px]">
                                            Scheduled
                                        </h1>
                                    </div>
                                </div>
                            )}
                            {notification?.NotifyUsers === "Draft" && (
                                <div className="flex flex-row justify-start items-center">
                                    <div className="flex flex-row justify-center items-center bg-[#FDF4EC] rounded-2xl px-5 py-2 w-20">
                                        <h1 className="text-[#954D05] text-xs font-normal font-poppins leading-[18px]">
                                            Draft
                                        </h1>
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-row justify-start items-center gap-1">
                                <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px]">
                                    {`${patientCountData[notification?.Audience]} / ${patientCountData["All Patients"]}`}
                                </h1>
                            </div>
                            <div className="flex flex-row justify-start items-center gap-1">
                                <button
                                    onClick={() => { navigate("/serviceManagement/notificationManagement/customNotification", {state: {notification: notification}})}}
                                    disabled={notification?.NotifyUsers === "Send Now"}
                                    className={`p-1 rounded-full group ${notification?.NotifyUsers === "Send Now" ? "bg-[#D9D9D9]" : "bg-[#E5F2FF]"} w-8 h-8`}
                                >
                                    <Tooltip message={`${notification?.NotifyUsers === "Send Now" ? "Cannot edit once sent" : "Edit"}`}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={`w-6 h-6 ${notification?.NotifyUsers === "Send Now" ? "text-[#8A8A8A]" : "text-[#3CA5DC]"}`}
                                        >
                                            <path
                                                d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </Tooltip>
                                </button>
                            </div>
                            <div className="flex flex-row bg-[#D9D9D900] justify-start items-start gap-1 border border-[#D4D4D4] h-14 w-full rounded-[4px] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-[#C0C0C0] scrollbar-track-[#EAEAEA] scrollbar-thumb-rounded-full scrollbar-track-rounded-full p-1">
                                <h1 className="text-[#545454] text-[10px] font-normal font-poppins leading-[20px] break-words">
                                    {notification?.message}
                                </h1>
                            </div>
                        </div>
                    </React.Fragment>
                ))}

                {filteredNotifications?.length !== 0 && (
                    <Pagination
                        totalItems={filteredNotifications?.length}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                )}
            </div>
        </div >
    );
}

export default NotificationDashboard;
